"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _auth = require("@/utils/auth");
var _validate = require("@/utils/validate");
var _string = require("@/utils/string");
var _file = require("@/utils/file");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    showForm: {
      type: Object,
      default: function _default() {
        return {
          title: true,
          pdf_file: true,
          documents: true
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          pdf_file: '',
          documents: ''
        };
      }
    },
    documentsOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currentFileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '追加';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateRequireOption = function validateRequireOption(rule, value, callback) {
      if (_this.showForm.documents && !value) {
        callback(new Error(rule.colname + 'を選択してください'));
      } else {
        callback();
      }
    };
    var validateRequireFileOption = function validateRequireFileOption(rule, value, callback) {
      if (_this.fileList.length === 0 || _this.fileList.length === undefined) {
        callback(new Error(rule.colname + 'を選択してください'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false,
      waitDialogVisible: false,
      fileList: [],
      orgImgData: '',
      rules: {
        title: [{
          required: true,
          trigger: 'blur',
          message: 'タイトルを入力してください'
        }],
        documents: [{
          required: true,
          validator: validateRequireOption,
          colname: '添付ファイル種別（雇用契約書）'
        }],
        fileList: [{
          required: true,
          validator: validateRequireFileOption,
          colname: '添付ファイル'
        }]
      }
    };
  },
  computed: {
    user_id: function user_id() {
      return (0, _auth.getStaffCode)();
    },
    user_name: function user_name() {
      return (0, _auth.getStaffName)();
    }
  },
  methods: {
    // ファイルリストのバッテンを押下した時
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = fileList;
      this.form.title = '';
    },
    // ファイルを追加した時
    handleAdd: function handleAdd(file, fileList) {
      if ((0, _validate.checkPDFFile)(file.name) === false) {
        this.$message.warning((0, _string.warningInformation)().PDFFileImportWarningMessages);
        this.fileList = [];
        return;
      }
      if (file.name) {
        this.form.title = (0, _file.getFileNameWithoutExtension)(file.name);
      }
      this.fileList = [file];
    },
    handleExceed: function handleExceed(file, fileList) {
      this.$message.warning('アップロードできるファイルは一つまでです');
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs.contractForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this2.centerDialogVisible = true;
      });
    },
    addContract: function addContract() {
      var _this3 = this;
      this.centerDialogVisible = false;
      this.waitDialogVisible = true;
      console.log('submit');
      this.$refs.contractForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        return _this3.$emit('postFormSubmit', _this3.form, _this3.fileList, _this3.waitDialogVisible);
      });
    }
  }
};