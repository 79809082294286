"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _DocumentCategory = require("@/lib/enum/DocumentCategory");
var _validate = require("@/utils/validate");
var _string = require("@/utils/string");
var _file = require("@/utils/file");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      contractTypes: [_DocumentCategory.DocumentCategory.UserDocumentType.EmploymentContract],
      fileList: []
    };
  },
  computed: {
    title: {
      get: function get() {
        return this.staff.contract.title;
      },
      set: function set(val) {
        this.staff.contract.title = val;
        this.updateStaffInStore();
      }
    },
    pdfFile: {
      get: function get() {
        return this.staff.contract.pdf_file;
      },
      set: function set(val) {
        this.staff.contract.pdf_file = val;
        this.updateStaffInStore();
      }
    },
    staff: function staff() {
      return this.$store.getters.selectedStaffs[this.index];
    }
  },
  methods: {
    handleAdd: function handleAdd(file, fileList) {
      if ((0, _validate.checkPDFFile)(file.name) === false) {
        this.$message.warning((0, _string.warningInformation)().PDFFileImportWarningMessages);
        this.fileList = [];
        return;
      }
      if (file.name) {
        this.title = (0, _file.getFileNameWithoutExtension)(file.name);
        this.pdfFile = file;
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      this.pdfFile = null;
      this.fileList = [];
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning('アップロードできるファイルは一つまでです');
    },
    updateStaffInStore: function updateStaffInStore() {
      var _this = this;
      var selectedStaffs = this.$store.getters.selectedStaffs.map(function (selectedStaff, index) {
        if (index === _this.index) {
          return _objectSpread({}, _this.staff);
        } else {
          return selectedStaff;
        }
      });
      this.$store.commit('user/SET_SELECTED_STAFFS', selectedStaffs);
    }
  }
};