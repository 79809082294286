"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentCategory = void 0;
/*
 * 注意：fast_staffのclient管理側にも同一のファイルあり、こちらを変更した場合、client管理側も更新すること
 */
var DocumentCategory = exports.DocumentCategory = {
  Acceptance: {
    TRUE: {
      val: true,
      label: '承認済'
    },
    FALSE: {
      val: false,
      label: '未承認'
    },
    CANCEL: {
      val: 99,
      label: '承認取り消し'
    }
  },
  UserDocumentType: {
    Contract: {
      val: 1,
      label: '契約書'
    },
    EmploymentStatement: {
      val: 2,
      label: '就業明示書'
    },
    EmploymentContract: {
      val: 3,
      label: '雇用契約書'
    },
    VariousApplications: {
      val: 4,
      label: '各種申請'
    }
  },
  ClientDocumentType: {
    Contract: {
      val: 1,
      label: '個別契約書'
    },
    ConfirmationInvoice: {
      val: 2,
      label: '事前確認請求書'
    },
    ConfirmationAttendance: {
      val: 3,
      label: '勤怠情報'
    },
    ConfirmationLaborNotice: {
      val: 4,
      label: '労働通知書'
    }
  }
};