var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "h3",
            { staticClass: "header-title" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toBack()
                    },
                  },
                },
                [_vm._v("戻る")]
              ),
              _vm._v(" セクション追加"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 5, type: "flex", justify: "start" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.strAddSection,
                          callback: function ($$v) {
                            _vm.strAddSection = $$v
                          },
                          expression: "strAddSection",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "" },
                          on: { click: _vm.addConfirmation },
                        },
                        [_vm._v("追加")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "table",
            { staticStyle: { width: "100%" } },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  attrs: { element: "tbody", animation: 200 },
                  on: { end: _vm.dragEnd },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                _vm._l(_vm.form, function (item, index) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _vm._v(" "),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.updateConfirmation(
                                  item.id,
                                  item.name
                                )
                              },
                            },
                          },
                          [_vm._v("編集")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteConfirmation(item.id)
                              },
                            },
                          },
                          [_vm._v("削除")]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.deleteDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deleteDialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("セクションを削除します。"),
                _c("br"),
                _vm._v("よろしいですか？"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.deleteDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.deleteSection } }, [
                    _vm._v("はい"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.remarksDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.remarksDialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("セクションを追加します。"),
                _c("br"),
                _vm._v("よろしいですか？"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.remarksDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.addSection } }, [
                    _vm._v("はい"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.updateSectionDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.updateSectionDialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("セクションを更新します。"),
                _c("br"),
                _vm._v("よろしいですか？"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.updateSectionDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.updateSection } }, [
                    _vm._v("はい"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.waitDialogVisible,
                width: "30%",
                "close-on-click-modal": false,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.waitDialogVisible = $event
                },
              },
            },
            [_c("span", [_vm._v("保存中！")])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "セクション編集",
            "show-close": false,
            visible: _vm.updateDialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updateDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "edit",
              attrs: { "label-position": "top", "label-width": "250px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "edit.strSectionName",
                            label: "セクション名",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "name", $$v)
                              },
                              expression: "edit.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.updateSectionConfirmation },
            },
            [_vm._v("更新")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.updateDialogVisible = false
                },
              },
            },
            [_vm._v("閉じる")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "100px" } }, [_vm._v("No.")]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticStyle: { "max-width": "110px", border: "none" },
            attrs: { align: "right" },
          },
          [_vm._v("セクション")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticStyle: { "max-width": "110px", border: "none" },
            attrs: { align: "left" },
          },
          [
            _c(
              "span",
              { staticStyle: { "font-size": "14px", "font-weight": "normal" } },
              [
                _vm._v(
                  "・セクション枠をクリックしたまま移動することで、並び替えを行うことができます。"
                ),
                _c("br"),
                _vm._v("・並び順はアプリにそのまま反映されます。"),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("th", { attrs: { width: "200px" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }