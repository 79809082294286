"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QA_SECTION_LIST = exports.QA_QUERY = exports.QA_LIST_QUERY = exports.QA_LIST_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var QA_LIST_QUERY = exports.QA_LIST_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String, $page_top: Int, $page_size: Int) {\n\tqas(order_by: {order_priority: asc, section: {order_priority: asc}}, where: \n    {_or: [{section: {name: {_ilike: $searchWord}}}, {title: {_ilike: $searchWord}}]},\n    offset: $page_top, limit: $page_size\n  ){\n  \tid\n    title\n    descriptions\n    published_at\n    order_priority\n    created_at\n    updated_at\n\t\tsection{\n      id\n      name\n    }\n  }\n}\n"])));
var QA_LIST_COUNT = exports.QA_LIST_COUNT = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String) {\n  qas_aggregate(where: {_or: [{section: {name: {_ilike: $searchWord}}}, {title: {_ilike: $searchWord}}]}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var QA_SECTION_LIST = exports.QA_SECTION_LIST = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n\tqa_sections(order_by: {order_priority: asc}){\n  \tid\n    name\n    order_priority\n  }\n}\n"])));
var QA_QUERY = exports.QA_QUERY = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: Int){\n  qas(where: {id: {_eq: $id}}) {\n  \tid\n    title\n    descriptions\n    published_at\n    order_priority\n    created_at\n    updated_at\n    section_id\n\t\tsection{\n      id\n      name\n    }\n    files {\n      id\n      name\n      url\n      order_priority\n      qa_id\n      created_at\n      updated_at\n    }\n  }\n}\n"])));