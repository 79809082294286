"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_RECRUITMENT_REMARKS = exports.UPDATE_RECRUITMENT_ADMIN_READING_FLAG = exports.SET_RECRUITMENT_UPDATE_STAFF_FLAG = exports.DEL_RECRUITMENT_STAFF = exports.CLEAR_MESSAGE_READING_FLAG = exports.ADD_RECRUITMENT_STAFF = exports.ADD_RECRUITMENT_MESSAGE_ADMIN = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var DEL_RECRUITMENT_STAFF = exports.DEL_RECRUITMENT_STAFF = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteRecruitmentStaff(\n  $id: Int\n) {\n  delete_recruitment_staff(where: {id: {_eq: $id}}) {\n    affected_rows\n    returning {\n      add_date\n      id\n      recruitment_id\n      staff_code\n    }\n  }\n}\n"])));
var ADD_RECRUITMENT_STAFF = exports.ADD_RECRUITMENT_STAFF = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation addRecruitmentStaff(\n  $recruitment_id: Int,\n  $staff_code: String\n  ) {\n  insert_recruitment_staff(objects: {\n    recruitment_id: $recruitment_id,\n    staff_code: $staff_code\n  }) {\n    affected_rows\n    returning {\n      id\n      recruitment_id\n      staff_code\n      add_date\n    }\n  }\n}\n"])));
var ADD_RECRUITMENT_MESSAGE_ADMIN = exports.ADD_RECRUITMENT_MESSAGE_ADMIN = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation addRecruitmentMessage(\n    $recruitment_id: Int,\n    $user_type: Int,\n    $admin_id: Int,\n    $message: String\n  ) {\n  insert_recruitment_message(objects: {\n    recruitment_id: $recruitment_id,\n    user_type: $user_type,\n    admin_id: $admin_id,\n    message: $message,\n    reading_flag: true\n  }) {\n    affected_rows\n    returning {\n      id\n      recruitment_id\n      user_type\n      message\n      client_id\n      admin_id\n      create_date\n      admin{\n        name\n      }\n    }\n  }\n}\n"])));
var UPDATE_RECRUITMENT_ADMIN_READING_FLAG = exports.UPDATE_RECRUITMENT_ADMIN_READING_FLAG = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateRecruitmentAdminReadingFlag(\n  $id: Int,\n  $admin_reading_flag: Boolean\n) {\n  update_recruitment_info(\n    where: {id: {_eq: $id}},\n    _set: {\n      admin_reading_flag: $admin_reading_flag\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      store_name\n      contact_name\n      phone_number\n      mail_address\n      occupation\n      site_address\n      start_period_date\n      end_period_date\n      shift\n      holiday\n      reception\n      reason\n      workplace_composition\n      prerequisite_type\n      prerequisite_spec\n      condition_type\n      condition_spec\n      job_description\n      staff_count\n      is_active\n      working_hours\n      client_uuid\n      client_reading_flag\n      admin_reading_flag\n    }  \n  }\n}\n"])));
var UPDATE_RECRUITMENT_REMARKS = exports.UPDATE_RECRUITMENT_REMARKS = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateRecruitmentRemarks(\n  $id: Int,\n  $remarks: String\n) {\n  update_recruitment_info(\n    where: {id: {_eq: $id}},\n    _set: {\n      remarks: $remarks\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      store_name\n      contact_name\n      phone_number\n      mail_address\n      occupation\n      site_address\n      start_period_date\n      end_period_date\n      shift\n      holiday\n      reception\n      reason\n      workplace_composition\n      prerequisite_type\n      prerequisite_spec\n      condition_type\n      condition_spec\n      job_description\n      staff_count\n      is_active\n      working_hours\n      client_uuid\n      client_reading_flag\n      admin_reading_flag\n    }  \n  }\n}\n"])));
var SET_RECRUITMENT_UPDATE_STAFF_FLAG = exports.SET_RECRUITMENT_UPDATE_STAFF_FLAG = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation setRecruitmentUpdateStaffFlag(\n  $id: Int,\n  $staff_update_flag: Boolean\n) {\n  update_recruitment_info(\n    where: {id: {_eq: $id}},\n    _set: {\n      staff_update_flag: $staff_update_flag\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      store_name\n      contact_name\n      phone_number\n      mail_address\n      occupation\n      site_address\n      start_period_date\n      end_period_date\n      shift\n      holiday\n      reception\n      reason\n      workplace_composition\n      prerequisite_type\n      prerequisite_spec\n      condition_type\n      condition_spec\n      job_description\n      staff_count\n      is_active\n      working_hours\n      client_uuid\n      client_reading_flag\n      admin_reading_flag\n    }  \n  }\n}\n"])));
var CLEAR_MESSAGE_READING_FLAG = exports.CLEAR_MESSAGE_READING_FLAG = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nmutation clearMessageReadFlag(\n  $recruitment_id: Int\n) {\n  update_recruitment_message(\n    where: {recruitment_id: {_eq: $recruitment_id}, client_id: {_is_null: false}},\n    _set: {\n      reading_flag: false\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      recruitment_id\n      user_type\n      message\n      client_id\n      admin_id\n      create_date\n      reading_flag\n    }  \n  }\n}\n"])));