var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c("div", { staticClass: "app-container" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-title", attrs: { model: _vm.form } }, [
          _c("p", [
            _vm._v(
              _vm._s(_vm.displayDocumentType(_vm.form[0].file_type)) + "の詳細"
            ),
          ]),
          _vm._v(" "),
          _vm.form[0].file_type !== 4
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "midButton",
                      on: { click: _vm.backToContractIndex },
                    },
                    [_vm._v("戻る")]
                  ),
                  _vm._v(" "),
                  _vm.isLoading === true
                    ? _c(
                        "el-button",
                        {
                          staticClass: "midButton",
                          attrs: { disabled: true, type: "primary" },
                        },
                        [_vm._v("書類の印刷")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "midButton",
                          attrs: { disabled: false, type: "primary" },
                          on: { click: _vm.print },
                        },
                        [_vm._v("書類の印刷")]
                      ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "midButton",
                      on: { click: _vm.backToContractIndex },
                    },
                    [_vm._v("戻る")]
                  ),
                ],
                1
              ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "スタッフ名", align: "left" } },
                [_vm._v("\n          " + _vm._s(_vm.user_name) + "\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "スタッフID", align: "left" } },
                [_vm._v("\n          " + _vm._s(_vm.user_id) + "\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", label: "タイトル", align: "left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.form[0].file_type !== 4
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": {
                          color: "gray",
                          background: "#DCF2FF",
                        },
                        data: _vm.form,
                        border: "",
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "承認状態", align: "left" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.displayAcceptanceType(
                                          scope.row.is_acceptance
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1272039963
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "承認日時", align: "left" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.displayDateTime(
                                          scope.row.date_of_acceptance
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2698012211
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "50%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "未読既読状態", align: "left" } },
                [
                  _vm.form[0].user_reading == false
                    ? _c("div", [_vm._v("未読")])
                    : _c("div", [_vm._v("既読")]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "top", "label-width": "250px" },
            },
            [
              _c("h3", { staticClass: "header-title" }, [
                _vm._v(_vm._s(_vm.displayDocumentType(_vm.form[0].file_type))),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-form-item", [
                        _c("iframe", {
                          attrs: {
                            src: _vm.form[0].file_url,
                            width: "100%",
                            height: "800",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "action-block" }, [
            _vm.form[0].file_type !== 4
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "midButton",
                        on: { click: _vm.backToContractIndex },
                      },
                      [_vm._v("戻る")]
                    ),
                    _vm._v(" "),
                    _vm.isLoading === true
                      ? _c(
                          "el-button",
                          {
                            staticClass: "midButton",
                            attrs: { disabled: true, type: "primary" },
                          },
                          [_vm._v("書類の印刷")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "midButton",
                            attrs: { disabled: false, type: "primary" },
                            on: { click: _vm.print },
                          },
                          [_vm._v("書類の印刷")]
                        ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "midButton",
                        on: { click: _vm.backToContractIndex },
                      },
                      [_vm._v("戻る")]
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { hidden: "" } }, [
        _c(
          "form",
          { attrs: { id: "printJS-form", method: "post" } },
          _vm._l(_vm.images, function (image) {
            return _c("div", { key: image }, [
              _c("img", { attrs: { src: image } }),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }