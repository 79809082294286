var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.backToRecruitmentIndex()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" 求人エントリー詳細"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "client_user[0].company_name",
                label: "会社名",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "store_name", label: "店名", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "contact_name",
                label: "担当者名",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "開始期間", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.displayDateTime(
                              scope.row.start_period_date,
                              "YYYY年MM月DD日"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "終了期間", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.displayDateTime(
                              scope.row.end_period_date,
                              "YYYY年MM月DD日"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "occupation", label: "職種", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "phone_number",
                label: "電話番号",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "mail_address",
                label: "メールアドレス",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "site_address",
                label: "現場住所",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "shift", label: "シフト内容", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "staff_count",
                label: "募集人数",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "working_hours",
                label: "勤務時間",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "holiday", label: "休日", align: "center" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "待遇", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.reception))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "募集理由", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.reason))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "職場構成", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.workplace_composition))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "働く上での必須条件（性格）", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.prerequisite_type))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "働く上での必須条件（資格）", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.prerequisite_spec))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "働く上でのできれば必要な条件（性格）",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.condition_type))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "働く上でのできれば必要な条件（資格）",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.condition_spec))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "仕事内容", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: { "white-space": "pre" },
                          attrs: { align: "left" },
                        },
                        [_vm._v(_vm._s(scope.row.job_description))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c("h3", { staticClass: "header-title" }, [
          _vm._v("備考欄（クライアント様には表示されません）"),
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: { "label-position": "top", "label-width": "250px" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "remarks" } },
              [
                _c("el-input", {
                  attrs: { type: "textarea", rows: 15 },
                  model: {
                    value: _vm.form[0].remarks,
                    callback: function ($$v) {
                      _vm.$set(_vm.form[0], "remarks", $$v)
                    },
                    expression: "form[0].remarks",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goSaveRemarks()
                  },
                },
              },
              [_vm._v("備考欄の保存")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c("h3", { staticClass: "header-title" }, [
          _vm._v("エントリースタッフ情報"),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form[0].recruitment_staff,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "user.id",
                label: "スタッフ番号",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "user.staff_type",
                label: "スタッフ区分",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "user.name", label: "氏名", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "user.gender", label: "性別", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "user.get_age", label: "年齢", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              staticClass: "buttons",
              attrs: { align: "center", "min-width": "75" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToDetail(scope.row.user.uid)
                            },
                          },
                        },
                        [_vm._v("詳細")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              staticClass: "buttons",
              attrs: { align: "center", "min-width": "75" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToDelStaff(scope.row.id)
                            },
                          },
                        },
                        [_vm._v("解除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-block" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.backToRecruitmentIndex()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goAddStaff()
                  },
                },
              },
              [_vm._v("スタッフの追加")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goMessage()
                  },
                },
              },
              [_vm._v("メッセージ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.deleteDialogVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.deleteDialogVisible = $event
              },
            },
          },
          [
            _c("span", [
              _vm._v("エントリースタッフを解除します。"),
              _c("br"),
              _vm._v("よろしいですか？"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.deleteDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.onDelete } }, [
                  _vm._v("はい"),
                ]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.remarksDialogVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.remarksDialogVisible = $event
              },
            },
          },
          [
            _c("span", [
              _vm._v("備考欄を保存します。"),
              _c("br"),
              _vm._v("よろしいですか？"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.remarksDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.onSaveRemarks } }, [
                  _vm._v("はい"),
                ]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.waitDialogVisible,
              width: "30%",
              "close-on-click-modal": false,
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.waitDialogVisible = $event
              },
            },
          },
          [_c("span", [_vm._v("保存中！")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }