"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _auth = require("@/utils/auth");
var _validate = require("@/utils/validate");
var _string = require("@/utils/string");
var _file = require("@/utils/file");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    showForm: {
      type: Object,
      default: function _default() {
        return {
          title: true,
          pdf_file: true
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          pdf_file: ''
        };
      }
    },
    currentFileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '追加';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateRequireFileOption = function validateRequireFileOption(rule, value, callback) {
      if (_this.fileList.length === 0 || _this.fileList.length === undefined) {
        callback(new Error(rule.colname + 'を選択してください'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false,
      fileList: [],
      orgImgData: '',
      rules: {
        title: [{
          required: true,
          trigger: 'blur',
          message: 'タイトルを入力してください'
        }],
        fileList: [{
          required: true,
          validator: validateRequireFileOption,
          colname: '添付ファイル'
        }]
      }
    };
  },
  computed: {
    company_name: function company_name() {
      return (0, _auth.getCompanyName)();
    }
  },
  methods: {
    // ファイルリストのバッテンを押下した時
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = fileList;
      this.form.title = '';
    },
    // ファイルを追加した時
    handleAdd: function handleAdd(file, fileList) {
      if ((0, _validate.checkPDFFile)(file.name) === false) {
        this.$message.warning((0, _string.warningInformation)().PDFFileImportWarningMessages);
        this.fileList = [];
        return;
      }
      if (file.name) {
        this.form.title = (0, _file.getFileNameWithoutExtension)(file.name);
      }
      this.fileList = [file];
    },
    handleExceed: function handleExceed(file, fileList) {
      this.$message.warning('アップロードできるファイルは一つまでです');
    },
    submit: function submit() {
      var _this2 = this;
      console.log('submit');
      // return
      this.$refs.laborNoticeForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this2.centerDialogVisible = true;
      });
    },
    addLaborNotice: function addLaborNotice() {
      console.log('addLaborNotice');
      return this.$emit('postFormSubmit', this.form, this.fileList);
    }
  }
};