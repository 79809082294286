var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" 書類一覧"),
          ],
          1
        ),
        _vm._v(" "),
        _c("ContractFilter"),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              margin: "32px",
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c(
              "div",
              [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.currentPage + 1) +
                      " / " +
                      _vm._s(_vm.maxPage + 1)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goTopPage()
                      },
                    },
                  },
                  [_vm._v("先頭ページ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goPrePage()
                      },
                    },
                  },
                  [_vm._v("前ページ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goNextPage()
                      },
                    },
                  },
                  [_vm._v("次ページ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goLastPage()
                      },
                    },
                  },
                  [_vm._v("最終ページ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goToAddContract()
                  },
                },
              },
              [_vm._v("書類の追加")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.contracts, stripe: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "title", label: "タイトル", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "公開日時", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.displayDateTime(
                                  scope.row.release_date,
                                  "YYYY年MM月DD日 HH:mm"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "承認日時", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.displayDateTime(
                                  scope.row.date_of_acceptance,
                                  "YYYY年MM月DD日 HH:mm"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "書類種別",
                    align: "center",
                    "min-width": "35",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.displayDocumentType(scope.row.file_type)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "承認有無",
                    align: "center",
                    "min-width": "35",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.displayAcceptanceType(
                                  scope.row.is_acceptance
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { "min-width": "50", label: "状態", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.is_release === true
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: scope.row.is_acceptance,
                                    type: "info",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.goToRelease(
                                        scope.row.id,
                                        scope.row.is_release
                                      )
                                      scope.row.is_release =
                                        !scope.row.is_release
                                    },
                                  },
                                },
                                [_vm._v("公開")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: scope.row.is_acceptance,
                                    type: "info",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.goToRelease(
                                        scope.row.id,
                                        scope.row.is_release
                                      )
                                      scope.row.is_release =
                                        !scope.row.is_release
                                    },
                                  },
                                },
                                [_vm._v("非公開")]
                              ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center", "min-width": "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("詳細")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center", "min-width": "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.is_release
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "info", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("削除")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-block" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.waitDialogVisible,
              width: "30%",
              "close-on-click-modal": false,
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.waitDialogVisible = $event
              },
            },
          },
          [_c("span", [_vm._v("更新中！")])]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "32px" } },
      [
        _c("label", [
          _vm._v(_vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goTopPage()
              },
            },
          },
          [_vm._v("先頭ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goPrePage()
              },
            },
          },
          [_vm._v("前ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goNextPage()
              },
            },
          },
          [_vm._v("次ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goLastPage()
              },
            },
          },
          [_vm._v("最終ページ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }