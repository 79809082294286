"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  sidebarKey: function sidebarKey(state) {
    return state.app.key;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.auth.token;
  },
  avatar: function avatar(state) {
    return state.auth.avatar;
  },
  name: function name(state) {
    return state.auth.name;
  },
  introduction: function introduction(state) {
    return state.auth.introduction;
  },
  roles: function roles(state) {
    return state.auth.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  admin: function admin(state) {
    return state.admin.admin;
  },
  admins: function admins(state) {
    return state.admin.admins;
  },
  recruitment: function recruitment(state) {
    return state.recruitment.recruitment;
  },
  recruitments: function recruitments(state) {
    return state.recruitment.recruitments;
  },
  searchRecruitmentWord: function searchRecruitmentWord(state) {
    return state.recruitment.searchRecruitmentWord;
  },
  recruitment_page_top: function recruitment_page_top(state) {
    return state.recruitment.page_top;
  },
  recruitment_page_last: function recruitment_page_last(state) {
    return state.recruitment.page_last;
  },
  recruitment_page_size: function recruitment_page_size(state) {
    return state.recruitment.page_size;
  },
  recruitment_page_count: function recruitment_page_count(state) {
    return state.recruitment.page_count;
  },
  client: function client(state) {
    return state.client.client;
  },
  clients: function clients(state) {
    return state.client.clients;
  },
  clients_contract: function clients_contract(state) {
    return state.client.clients_contract;
  },
  clients_invoice: function clients_invoice(state) {
    return state.client.clients_invoice;
  },
  clients_attendance: function clients_attendance(state) {
    return state.client.clients_attendance;
  },
  searchClientWord: function searchClientWord(state) {
    return state.client.searchClientWord;
  },
  searchClientContractWord: function searchClientContractWord(state) {
    return state.client.searchContractWord;
  },
  searchClientInvoiceWord: function searchClientInvoiceWord(state) {
    return state.client.searchInvoiceWord;
  },
  searchClientAttendancWord: function searchClientAttendancWord(state) {
    return state.client.searchAttendancWord;
  },
  searchClientLaborNoticeWord: function searchClientLaborNoticeWord(state) {
    return state.client.searchLaborNoticeWord;
  },
  searchClientInvoiceAcceptance: function searchClientInvoiceAcceptance(state) {
    return state.client.searchInvoiceAcceptance;
  },
  searchClientContractAcceptance: function searchClientContractAcceptance(state) {
    return state.client.searchContractAcceptance;
  },
  client_page_top: function client_page_top(state) {
    return state.client.page_top;
  },
  client_page_last: function client_page_last(state) {
    return state.client.page_last;
  },
  client_page_size: function client_page_size(state) {
    return state.client.page_size;
  },
  client_page_count: function client_page_count(state) {
    return state.client.page_count;
  },
  labor_notice: function labor_notice(state) {
    return state.client.labor_notice;
  },
  client_doc_page_top: function client_doc_page_top(state) {
    return state.client.page_doc_top;
  },
  client_doc_page_last: function client_doc_page_last(state) {
    return state.client.page_doc_last;
  },
  client_doc_page_size: function client_doc_page_size(state) {
    return state.client.page_doc_size;
  },
  client_doc_page_count: function client_doc_page_count(state) {
    return state.client.page_doc_count;
  },
  currentAdmin: function currentAdmin(state) {
    return state.auth.currentAdmin;
  },
  adminsAggregate: function adminsAggregate(state) {
    return state.admin.adminsAggregate;
  },
  searchAdminWord: function searchAdminWord(state) {
    return state.admin.searchAdminWord;
  },
  adminTotalCount: function adminTotalCount(state) {
    return state.admin.totalCount;
  },
  admin_page_top: function admin_page_top(state) {
    return state.admin.page_top;
  },
  admin_page_last: function admin_page_last(state) {
    return state.admin.page_last;
  },
  admin_page_size: function admin_page_size(state) {
    return state.admin.page_size;
  },
  admin_page_count: function admin_page_count(state) {
    return state.admin.page_count;
  },
  chatUsers: function chatUsers(state) {
    return state.chat.chatUsers;
  },
  chatRoom: function chatRoom(state) {
    return state.chat.room;
  },
  chatRooms: function chatRooms(state) {
    return state.chat.rooms;
  },
  chatRoomUserID: function chatRoomUserID(state) {
    return state.chat.roomUsers;
  },
  chatRoomUsers: function chatRoomUsers(state) {
    return state.chat.roomUsers;
  },
  chatClients: function chatClients(state) {
    return state.client.chatClients;
  },
  chatClientRoom: function chatClientRoom(state) {
    return state.client.chatClientRoom;
  },
  chatMessages: function chatMessages(state) {
    return state.chat.messages;
  },
  searchChatUserWord: function searchChatUserWord(state) {
    return state.chat.searchChatUserWord;
  },
  ch_page_top: function ch_page_top(state) {
    return state.chat.page_top;
  },
  ch_page_last: function ch_page_last(state) {
    return state.chat.page_last;
  },
  ch_page_size: function ch_page_size(state) {
    return state.chat.page_size;
  },
  ch_page_count: function ch_page_count(state) {
    return state.chat.page_count;
  },
  recruitmentMessages: function recruitmentMessages(state) {
    return state.recruitment.messages;
  },
  newss: function newss(state) {
    return state.news.newss;
  },
  news: function news(state) {
    return state.news.news;
  },
  newsDistributions: function newsDistributions(state) {
    return state.news.newsDistributions;
  },
  newsSurveyAggregate: function newsSurveyAggregate(state) {
    return state.news.newsSurveyAggregate;
  },
  newsSurveyAnswers: function newsSurveyAnswers(state) {
    return state.news.newsSurveyAnswers;
  },
  searchNewsText: function searchNewsText(state) {
    return state.news.searchNewsText;
  },
  searchNewsDistribution: function searchNewsDistribution(state) {
    return state.news.searchNewsDistribution;
  },
  news_page_top: function news_page_top(state) {
    return state.news.page_top;
  },
  news_page_last: function news_page_last(state) {
    return state.news.page_last;
  },
  news_page_size: function news_page_size(state) {
    return state.news.page_size;
  },
  news_page_count: function news_page_count(state) {
    return state.news.page_count;
  },
  users: function users(state) {
    return state.user.users;
  },
  user: function user(state) {
    return state.user.user;
  },
  sexList: function sexList(state) {
    return state.user.sexList;
  },
  contracts: function contracts(state) {
    return state.user.contracts;
  },
  contractsIndex: function contractsIndex(state) {
    return state.user.contractsIndex;
  },
  requests: function requests(state) {
    return state.user.requests;
  },
  searchKeyword: function searchKeyword(state) {
    return state.user.searchKeyword;
  },
  searchBy: function searchBy(state) {
    return state.user.searchBy;
  },
  searchStaffType: function searchStaffType(state) {
    return state.user.searchTermType;
  },
  searchContractTitle: function searchContractTitle(state) {
    return state.user.searchContractTitle;
  },
  searchContractDocumentType: function searchContractDocumentType(state) {
    return state.user.searchContractDocumentType;
  },
  searchContractAcceptance: function searchContractAcceptance(state) {
    return state.user.searchContractAcceptance;
  },
  searchContractWorkflowType: function searchContractWorkflowType(state) {
    return state.user.searchContractWorkflowType;
  },
  searchContractReadingType: function searchContractReadingType(state) {
    return state.user.searchContractReadingType;
  },
  userTypeList: function userTypeList(state) {
    return state.user.userTypeList;
  },
  page_top: function page_top(state) {
    return state.user.page_top;
  },
  page_last: function page_last(state) {
    return state.user.page_last;
  },
  page_size: function page_size(state) {
    return state.user.page_size;
  },
  page_count: function page_count(state) {
    return state.user.page_count;
  },
  contract_page_top: function contract_page_top(state) {
    return state.user.contract_page_top;
  },
  contract_page_last: function contract_page_last(state) {
    return state.user.contract_page_last;
  },
  contract_page_size: function contract_page_size(state) {
    return state.user.contract_page_size;
  },
  contract_page_count: function contract_page_count(state) {
    return state.user.contract_page_count;
  },
  request_page_last: function request_page_last(state) {
    return state.user.request_page_last;
  },
  siteusers: function siteusers(state) {
    return state.siteuser.siteusers;
  },
  searchSiteUserWord: function searchSiteUserWord(state) {
    return state.siteuser.searchSiteUserWord;
  },
  siteuser_page_top: function siteuser_page_top(state) {
    return state.siteuser.page_top;
  },
  siteuser_page_last: function siteuser_page_last(state) {
    return state.siteuser.page_last;
  },
  siteuser_page_size: function siteuser_page_size(state) {
    return state.siteuser.page_size;
  },
  siteuser_page_count: function siteuser_page_count(state) {
    return state.siteuser.page_count;
  },
  qas: function qas(state) {
    return state.qa.qas;
  },
  searchQaWord: function searchQaWord(state) {
    return state.qa.searchWord;
  },
  qa_page_top: function qa_page_top(state) {
    return state.qa.page_top;
  },
  qa_page_last: function qa_page_last(state) {
    return state.qa.page_last;
  },
  qa_page_size: function qa_page_size(state) {
    return state.qa.page_size;
  },
  qa_page_count: function qa_page_count(state) {
    return state.qa.page_count;
  },
  isQaEditMode: function isQaEditMode(state) {
    return state.qa.isEditMode;
  },
  fbuser: function fbuser(state) {
    return state.auth.user;
  },
  selectedStaffs: function selectedStaffs(state) {
    return state.user.selectedStaffs;
  },
  selectingMode: function selectingMode(state) {
    return state.user.selectingMode;
  }
};
var _default = exports.default = getters;