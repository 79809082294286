var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row_full", staticStyle: { "flex-grow": "1" } },
    [
      _c(
        "div",
        { staticStyle: { "align-content": "start", "flex-grow": "1" } },
        [
          _c(
            "div",
            {
              staticClass: "row_full",
              staticStyle: {
                "align-content": "space-between",
                flex: "max-content",
              },
            },
            [
              _c(
                "div",
                { staticClass: "parent", staticStyle: { width: "50%" } },
                [
                  _c("div", { staticClass: "label-text" }, [
                    _vm._v("スタッフ名"),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.staff.name))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "parent", staticStyle: { width: "50%" } },
                [
                  _c("div", { staticClass: "label-text" }, [
                    _vm._v("スタッフID"),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.staff.id))]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "parent", staticStyle: { width: "75%" } },
            [
              _c(
                "div",
                {
                  staticClass: "label-text",
                  staticStyle: { "margin-bottom": "12px" },
                },
                [_vm._v("添付ファイル種類 (雇用契約書)")]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "label-text",
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "選択" },
                  on: {
                    change: function ($event) {
                      _vm.staff.contract.type = $event
                    },
                  },
                  model: {
                    value: _vm.staff.contract.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.staff.contract, "type", $$v)
                    },
                    expression: "staff.contract.type",
                  },
                },
                _vm._l(_vm.contractTypes, function (item) {
                  return _c("el-option", {
                    key: item.val,
                    attrs: { label: item.label, value: item.val },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "parent",
          staticStyle: { "flex-grow": "1", "max-width": "50%" },
        },
        [
          _c("div", { staticClass: "label-text" }, [_vm._v("タイトル")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "○○社の雇用契約" },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "label-text" }, [
            _vm._v("添付ファイル (PDF)"),
          ]),
          _vm._v(" "),
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload",
              attrs: {
                drag: "",
                action: "",
                limit: 1,
                "on-change": _vm.handleAdd,
                "on-remove": _vm.handleRemove,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
                "list-type": "pdf",
                "auto-upload": false,
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("ファイルをドロップする"),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }