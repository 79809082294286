var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-container" }, [
        _c(
          "h3",
          {
            staticClass: "header",
            staticStyle: {
              display: "flex",
              "margin-right": "32px",
              "justify-content": "space-between",
              "align-items": "center",
            },
          },
          [
            _c(
              "div",
              { staticClass: "header-title" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToBack()
                      },
                    },
                  },
                  [_vm._v("戻る")]
                ),
                _vm._v("\n         雇用契約書の追加（複数人）\n      "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    style: _vm.buttonStyle,
                    attrs: {
                      disabled: !_vm.allContractOK,
                      type: "info",
                      loading: _vm.uploadingDialog,
                      plain: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.confirmSendAllStaffContract()
                      },
                    },
                  },
                  [_vm._v(" 一括送信\n          ")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "body-text" },
          _vm._l(_vm.selectedStaffs, function (staff, index) {
            return _c(
              "div",
              { key: index, staticClass: "row_full" },
              [
                _c("div", { staticClass: "index-cell" }, [
                  _vm._v("No. " + _vm._s(index + 1)),
                ]),
                _vm._v(" "),
                _c(
                  "el-card",
                  { staticClass: "full-size-card" },
                  [_c("MultiStaffContract", { attrs: { index: index } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "delete-button",
                    staticStyle: { width: "200px" },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      staticStyle: { "font-size": "2.5em" },
                      on: {
                        click: function ($event) {
                          return _vm.removeStaff(staff)
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "確認",
            visible: _vm.dialogVisibleDeleteStaff,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleDeleteStaff = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.staffToRemove.name) + "を削除しますか？"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleDeleteStaff = false
                    },
                  },
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRemoveStaff },
                },
                [_vm._v("削除")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "確認",
            visible: _vm.uploadingDialog,
            width: "30%",
            "close-on-click-modal": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadingDialog = $event
            },
          },
        },
        [_c("span", [_vm._v("登録中！")])]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "確認",
            visible: _vm.dialogVisibleSendAllStaffContract,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleSendAllStaffContract = $event
            },
          },
        },
        [
          _c("span", [_vm._v("書類を一括送信します。よろしいですか?")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleSendAllStaffContract = false
                    },
                  },
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.sendAllStaffContract },
                },
                [_vm._v("送信")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }