"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    showForm: {
      type: Object,
      default: function _default() {
        return {
          userName: true,
          email: true,
          password: true,
          icon: true,
          permission: true,
          hasChatRoom: true,
          roomComment: false
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          userName: '',
          email: '',
          password: '',
          icon: '',
          confirmedPassword: '',
          permission: '',
          hasChatRoom: false,
          roomComment: ''
        };
      }
    },
    permissionOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currentFileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '新規作成';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('メールアドレスを正しく入力してください'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('パスワードを入力してください'));
      } else {
        if (_this.showForm.confirmedPassword && _this.form.confirmedPassword !== '') {
          _this.$refs.userForm.validateField('confirmedPassword');
        }
        callback();
      }
    };
    var confimedPassword = function confimedPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('確認用パスワードを入力してください'));
      } else if (_this.showForm.password && value !== _this.form.password) {
        callback(new Error('パスワードが確認用の値と一致しません'));
      } else {
        callback();
      }
    };
    var validateRequireOption = function validateRequireOption(rule, value, callback) {
      if (_this.showForm.permission && !value) {
        callback(new Error(rule.colname + 'を選択してください'));
      } else {
        callback();
      }
    };
    return {
      orgImgData: '',
      passwordType: 'password',
      capsTooltip: false,
      centerDialogVisible: false,
      rules: {
        userName: [{
          required: true,
          trigger: 'blur',
          message: 'ユーザー名を入力してください'
        }],
        email: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        confirmedPassword: [{
          required: true,
          trigger: 'blur',
          validator: confimedPassword
        }],
        permission: [{
          required: true,
          validator: validateRequireOption,
          colname: '権限'
        }]
      }
    };
  },
  computed: {
    fileList: {
      get: function get() {
        return this.currentFileList;
      },
      set: function set(value) {
        this.$parent.currentFileList = value;
      }
    }
  },
  methods: {
    // パスワード確認
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    // ファイルリストのバッテンを押下した時
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // ファイルを追加した時
    handleAdd: function handleAdd(file, fileList) {
      this.fileList = [file];
    },
    handleExceed: function handleExceed(file, fileList) {
      this.$message.warning('アップロードできるファイルは一つまでです');
    },
    submit: function submit() {
      var _this3 = this;
      console.log('submit');
      // return
      this.$refs.userForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        return _this3.$emit('postFormSubmit', _this3.form, _this3.fileList);
      });
    }
  }
};