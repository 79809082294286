var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("h3", { staticClass: "header-title" }, [
          _vm._v("サイトユーザー一覧"),
        ]),
        _vm._v(" "),
        _c("SiteUserFilter"),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goTopPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.client, stripe: "", fit: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "サイトネーム",
                align: "center",
                "min-width": "75",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "email",
                label: "メールアドレス",
                align: "center",
                "min-width": "75",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              staticClass: "buttons",
              attrs: { align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToEdit(scope.row.uid)
                            },
                          },
                        },
                        [_vm._v("編集")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              staticClass: "buttons",
              attrs: { align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToRoomIndex(scope.row.uid)
                            },
                          },
                        },
                        [_vm._v("スタッフ一覧")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "32px" } },
      [
        _c("label", [
          _vm._v(_vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goTopPage()
              },
            },
          },
          [_vm._v("先頭ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goPrePage()
              },
            },
          },
          [_vm._v("前ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goNextPage()
              },
            },
          },
          [_vm._v("次ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goLastPage()
              },
            },
          },
          [_vm._v("最終ページ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }