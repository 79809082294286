var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "h3",
            { staticClass: "header-title" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToBack()
                    },
                  },
                },
                [_vm._v("戻る")]
              ),
              _vm._v(" スタッフ詳細"),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  on: {
                    click: function ($event) {
                      return _vm.openStaffEditDialog()
                    },
                  },
                },
                [_vm._v("スタッフ情報編集")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.user,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "スタッフ番号", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "entry_date", label: "登録日", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "staff_type",
                  label: "スタッフ区分",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: " 検索対象", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.displayStaffType(scope.row.is_search)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.user,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "home_station",
                  label: "最寄駅",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "home_station_line",
                  label: "沿線1",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "home_station_line_2",
                  label: "沿線2",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.user,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "phone_number",
                  label: "電話番号",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone_number_memo",
                  label: "電話番号(備考)",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.user,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "mobile_phone_number",
                  label: "携帯電話",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mobile_phone_number_memo",
                  label: "携帯電話(備考)",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "divider" }),
          _vm._v(" "),
          _c("h3", { staticClass: "header-title" }, [_vm._v("略歴")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "top", "label-width": "250px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_name",
                                $$v
                              )
                            },
                            expression: "display_information.disp_name",
                          },
                        },
                        [_vm._v("名前を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": {
                      color: "gray",
                      background: "#DCF2FF",
                    },
                    data: _vm.user,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "氏名(漢字)",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name_furigana",
                      label: "氏名(ｶﾅ)",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_birthday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_birthday",
                                $$v
                              )
                            },
                            expression: "display_information.disp_birthday",
                          },
                        },
                        [_vm._v("生年月日を表示する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.display_information, "disp_sex", $$v)
                            },
                            expression: "display_information.disp_sex",
                          },
                        },
                        [_vm._v("性別を表示する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_age,
                            callback: function ($$v) {
                              _vm.$set(_vm.display_information, "disp_age", $$v)
                            },
                            expression: "display_information.disp_age",
                          },
                        },
                        [_vm._v("年齢を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": {
                      color: "gray",
                      background: "#DCF2FF",
                    },
                    data: _vm.user,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "生年月日", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.displayDateTime(
                                    scope.row.birthday,
                                    "YYYY年MM月DD日"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "gender", label: "性別", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "get_age", label: "年齢", align: "center" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_address",
                                $$v
                              )
                            },
                            expression: "display_information.disp_address",
                          },
                        },
                        [_vm._v("住所を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": {
                      color: "gray",
                      background: "#DCF2FF",
                    },
                    data: _vm.user,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "post_code",
                      label: "郵便番号",
                      align: "center",
                      "min-width": "20",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "住所", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.address) +
                                "    " +
                                _vm._s(scope.row.address_2) +
                                "    " +
                                _vm._s(scope.row.address_3) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_final_education,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_final_education",
                                $$v
                              )
                            },
                            expression:
                              "display_information.disp_final_education",
                          },
                        },
                        [_vm._v("最終学歴を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "final_education_year", label: "年" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.final_education_year,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "final_education_year", $$v)
                              },
                              expression: "form.final_education_year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "final_education_month", label: "月" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.final_education_month,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "final_education_month", $$v)
                              },
                              expression: "form.final_education_month",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "final_education_exp",
                            label: "最終学歴",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.final_education_exp,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "final_education_exp", $$v)
                              },
                              expression: "form.final_education_exp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_work_history,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_work_history",
                                $$v
                              )
                            },
                            expression: "display_information.disp_work_history",
                          },
                        },
                        [_vm._v("職歴（直近のもの）を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year1", label: "年" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year1", $$v)
                              },
                              expression: "form.work_exp_year1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month1", label: "月" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month1", $$v)
                              },
                              expression: "form.work_exp_month1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "work_exp1",
                            label: "職歴（直近のもの）",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp1", $$v)
                              },
                              expression: "form.work_exp1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year2" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year2", $$v)
                              },
                              expression: "form.work_exp_year2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month2" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month2", $$v)
                              },
                              expression: "form.work_exp_month2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp2" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp2", $$v)
                              },
                              expression: "form.work_exp2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year3" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year3,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year3", $$v)
                              },
                              expression: "form.work_exp_year3",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month3" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month3,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month3", $$v)
                              },
                              expression: "form.work_exp_month3",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp3" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp3,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp3", $$v)
                              },
                              expression: "form.work_exp3",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year4" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year4,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year4", $$v)
                              },
                              expression: "form.work_exp_year4",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month4" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month4,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month4", $$v)
                              },
                              expression: "form.work_exp_month4",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp4" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp4,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp4", $$v)
                              },
                              expression: "form.work_exp4",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year5" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year5,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year5", $$v)
                              },
                              expression: "form.work_exp_year5",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month5" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month5,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month5", $$v)
                              },
                              expression: "form.work_exp_month5",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp5" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp5,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp5", $$v)
                              },
                              expression: "form.work_exp5",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year6" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year6,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year6", $$v)
                              },
                              expression: "form.work_exp_year6",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month6" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month6,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month6", $$v)
                              },
                              expression: "form.work_exp_month6",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp6" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp6,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp6", $$v)
                              },
                              expression: "form.work_exp6",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year7" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year7,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year7", $$v)
                              },
                              expression: "form.work_exp_year7",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month7" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month7,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month7", $$v)
                              },
                              expression: "form.work_exp_month7",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp7" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp7,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp7", $$v)
                              },
                              expression: "form.work_exp7",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_year8" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_year8,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_year8", $$v)
                              },
                              expression: "form.work_exp_year8",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp_month8" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp_month8,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp_month8", $$v)
                              },
                              expression: "form.work_exp_month8",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "work_exp8" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.work_exp8,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "work_exp8", $$v)
                              },
                              expression: "form.work_exp8",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_qualification,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_qualification",
                                $$v
                              )
                            },
                            expression:
                              "display_information.disp_qualification",
                          },
                        },
                        [_vm._v("資格を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "requirements", label: "資格" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 8 },
                    model: {
                      value: _vm.form.requirements,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "requirements", $$v)
                      },
                      expression: "form.requirements",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.display_information
                                .disp_transportation_facilities,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_transportation_facilities",
                                $$v
                              )
                            },
                            expression:
                              "display_information.disp_transportation_facilities",
                          },
                        },
                        [_vm._v("交通機関を表示する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.display_information.disp_desired_work_day,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_desired_work_day",
                                $$v
                              )
                            },
                            expression:
                              "display_information.disp_desired_work_day",
                          },
                        },
                        [_vm._v("勤務希望日数を表示する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_overtime,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_overtime",
                                $$v
                              )
                            },
                            expression: "display_information.disp_overtime",
                          },
                        },
                        [_vm._v("残業を表示する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_preferred_date,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_preferred_date",
                                $$v
                              )
                            },
                            expression:
                              "display_information.disp_preferred_date",
                          },
                        },
                        [_vm._v("希望スタート日時を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "transportation", label: "交通機関" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.transportation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "transportation", $$v)
                              },
                              expression: "form.transportation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "working_days",
                            label: "勤務希望日数",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.working_days,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "working_days", $$v)
                              },
                              expression: "form.working_days",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "overtime", label: "残業" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.overtime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "overtime", $$v)
                              },
                              expression: "form.overtime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "start_dt",
                            label: "希望スタート日時",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.start_dt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "start_dt", $$v)
                              },
                              expression: "form.start_dt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.display_information.disp_pr_comment,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.display_information,
                                "disp_pr_comment",
                                $$v
                              )
                            },
                            expression: "display_information.disp_pr_comment",
                          },
                        },
                        [_vm._v("PR内容を表示する")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "pr_comment", label: "PR内容" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 15 },
                    model: {
                      value: _vm.form.pr_comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pr_comment", $$v)
                      },
                      expression: "form.pr_comment",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { staticClass: "divider" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "remarks",
                    label: "備考欄（クライアント様には表示されません）",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 15 },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-block" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToBack()
                    },
                  },
                },
                [_vm._v("戻る")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goToBiography()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.biography_btn))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goToContract()
                    },
                  },
                },
                [_vm._v("書類一覧")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "確認",
            visible: _vm.centerDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.biography_btn) + "を行いますか？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("いいえ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.biographyProcess },
                },
                [_vm._v("はい")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "スタッフ情報編集",
            "show-close": false,
            "before-close": _vm.closeevent,
            visible: _vm.updateDialogVisible,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updateDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "edit",
              attrs: { "label-position": "top", "label-width": "250px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "edit", label: "氏名(漢字)" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "name", $$v)
                              },
                              expression: "edit.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name_furigana", label: "氏名(ｶﾅ)" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.name_furigana,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "name_furigana", $$v)
                              },
                              expression: "edit.name_furigana",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "post_code", label: "郵便番号" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.post_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "post_code", $$v)
                              },
                              expression: "edit.post_code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "address", label: "住所" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "address", $$v)
                              },
                              expression: "edit.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone_number", label: "電話番号" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.phone_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "phone_number", $$v)
                              },
                              expression: "edit.phone_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "phone_number_memo",
                            label: "電話番号（備考）",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.phone_number_memo,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "phone_number_memo", $$v)
                              },
                              expression: "edit.phone_number_memo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "mobile_phone_number",
                            label: "携帯番号",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.mobile_phone_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "mobile_phone_number", $$v)
                              },
                              expression: "edit.mobile_phone_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "mobile_phone_number_memo",
                            label: "携帯番号（備考）",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.edit.mobile_phone_number_memo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.edit,
                                  "mobile_phone_number_memo",
                                  $$v
                                )
                              },
                              expression: "edit.mobile_phone_number_memo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onUpdate } },
            [_vm._v("更新")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.updateDialogVisible = false
                },
              },
            },
            [_vm._v("戻る")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "確認",
            visible: _vm.waitDialogVisible,
            width: "30%",
            "close-on-click-modal": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.waitDialogVisible = $event
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.biography_btn) + "中！")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }