"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$showAboutDialog = $showAboutDialog;
exports.$showConfirmDialog = $showConfirmDialog;
var _defineProperty2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vue = _interopRequireDefault(require("vue"));
var _index = _interopRequireDefault(require("./../components/CustomDialog/index.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var DialogConstructor = _vue.default.extend(_index.default);
function $_showDialog(options) {
  return new Promise(function (resolve, reject) {
    var instance = new DialogConstructor({
      propsData: _objectSpread({}, options)
    });
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.$on('yes', function () {
      resolve(true);
      instance.$destroy();
      document.body.removeChild(instance.$el);
    });
    instance.$on('no', function () {
      resolve(false);
      instance.$destroy();
      document.body.removeChild(instance.$el);
    });
    instance.$on('close', function () {
      resolve(null);
      instance.$destroy();
      document.body.removeChild(instance.$el);
    });
  });
}
function $showConfirmDialog(options) {
  return $_showDialog(_objectSpread(_objectSpread({}, options), {}, {
    type: 'confirm'
  }));
}
function $showAboutDialog(options) {
  return $_showDialog(_objectSpread(_objectSpread({}, options), {}, {
    type: 'about'
  }));
}