"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
exports.updateUid = updateUid;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * ログイン処理
 * @param {username, password} data
 */
function login(data) {
  // const username = process.env.VUE_APP_FIREBASE_DEFAULT_NAME
  // const password = process.env.VUE_APP_FIREBASE_DEFAULT_PASSWORD
  //  return firebase.auth().signInWithEmailAndPassword(
  //    data.username,
  //    data.password
  //  ).catch((err) => {
  //    console.log(err.message)
  //  }).then((response) => {
  //    if (response !== undefined) {
  var token = {
    token: 'admin-token',
    uid: 111,
    email: data.username
  };
  return {
    code: 20000,
    data: token
  };
  //    }
  //  })
}

/**
 * 内部的なログイン処理
 * @param {username, password} data
 */
/*
function loginInternal(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
*/
/**
 *  ユーザロール
 *  admin, editor
 */
var users_info = {
  'admin-token': {
    roles: ['admin'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/person_no_image.png',
    introduction: 'admin',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/person_no_image.png',
    introduction: 'editor',
    name: 'Normal Editor'
  }
};
function getInfo(token) {
  var info = users_info[token];
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: info
    };
    resolve(result);
  });
}
function logout() {
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: 'success'
    };
    resolve(result);
  });
}
function updateUid(data) {
  return (0, _request.default)({
    url: '/adminLogin',
    method: 'post',
    data: data
  });
}