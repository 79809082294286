"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ADMIN_UNREAD_ENTRY_COUNT = exports.ADMIN_QUERY = exports.ADMINS_LIST_QUERY = exports.ADMINS_LIST_COUNT = exports.ADMINS_EMAIL_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var ADMIN_QUERY = exports.ADMIN_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($id: uuid) {\n  admin(where: {uid: {_eq: $id}}) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    room_comment\n    is_active\n  }\n}\n"])));
var ADMINS_LIST_COUNT = exports.ADMINS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String) {\n  admin_aggregate(order_by: {id: asc}, where: {_or: [{name: {_ilike: $search_word}}, {email: {_ilike: $search_word}}]}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var ADMINS_LIST_QUERY = exports.ADMINS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery($search_word: String, $page_top: Int, $page_size: Int) {\n  admin(order_by: {id: asc},\n    where: {\n      _or: [\n        {name: {_ilike: $search_word}},\n        {email: {_ilike: $search_word}}\n      ]}, offset: $page_top, limit: $page_size) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    is_active\n  }\n}\n"])));
var ADMINS_EMAIL_COUNT = exports.ADMINS_EMAIL_COUNT = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ($email: String) {\n  admin_aggregate(where: {email: {_eq: $email}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var ADMIN_UNREAD_ENTRY_COUNT = exports.ADMIN_UNREAD_ENTRY_COUNT = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  jobs_aggregate(where: {has_unread_entries: {_eq: true}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));