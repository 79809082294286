"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_TYPE_LIST = exports.USER_QUERY = exports.USERS_LIST_QUERY_EX = exports.USERS_LIST_COUNT = exports.USERS_BY_IDS = exports.SELECT_USER_QUERY = exports.REQUEST_LIST_QUERY = exports.REQUEST_LIST_COUNT = exports.GET_USER = exports.GET_REQUEST_READING = exports.GET_CONTRACT = exports.ENTRY_JOB_USERS_LIST_QUERY_EX = exports.ENTRY_JOB_USERS_LIST_COUNT = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_DESC = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_ASC = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY = exports.EMPLOYMENT_CONTRACTS_LIST_COUNT = exports.DELETE_CONTRACT_WITH_DELETED_ID = exports.CONTRACTS_LIST_QUERY_SORT_DESC = exports.CONTRACTS_LIST_QUERY_SORT_ASC = exports.CONTRACTS_LIST_QUERY = exports.CONTRACTS_LIST_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22;
var USERS_LIST_COUNT = exports.USERS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery ($keyword: String, $searchStaffType: String, $searchBy: String) {\n  user_aggregate: search_user_v2_aggregate(args: {search_by: $searchBy, search_staff_type: $searchStaffType, keyword: $keyword}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var ENTRY_JOB_USERS_LIST_COUNT = exports.ENTRY_JOB_USERS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($keyword: String, $searchStaffType: String, $searchBy: String, $searchJobId: Int) {\n  user_aggregate: search_entry_job_users_aggregate(args: {search_by: $searchBy, search_staff_type: $searchStaffType, keyword: $keyword, search_job_id: $searchJobId}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var USERS_LIST_QUERY_EX = exports.USERS_LIST_QUERY_EX = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery($keyword: String, $searchStaffType: String, $searchBy: String, $page_top: Int, $page_size: Int) {\n  search_user: search_user_v2(args: {page_size: $page_size, page_top: $page_top, keyword: $keyword, search_staff_type: $searchStaffType, search_by: $searchBy}) {\n    get_age\n    entry_date\n    gender\n    name\n    id\n    staff_type\n    uid\n    admin_name\n  }\n}\n"])));
var ENTRY_JOB_USERS_LIST_QUERY_EX = exports.ENTRY_JOB_USERS_LIST_QUERY_EX = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ($keyword: String, $searchStaffType: String, $searchBy: String, $page_top: Int, $page_size: Int, $searchJobId: Int) {\n  search_user: search_entry_job_users(args: {page_size: $page_size, page_top: $page_top, keyword: $keyword, search_staff_type: $searchStaffType, search_by: $searchBy, search_job_id: $searchJobId}) {\n    get_age\n    entry_date\n    gender\n    name\n    id\n    staff_type\n    uid\n    admin_name\n  }\n}\n"])));
var USER_QUERY = exports.USER_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery($uid: uuid){\n  user(where: {uid: {_eq: $uid}}) {\n    address\n    address_2\n    address_3\n    admin_name\n    get_age\n    birthday\n    blood_type\n    blood_type_rh\n    entry_date\n    gender\n    has_child\n    has_mate\n    home_station\n    home_station_line\n    home_station_line_2\n    is_search\n    mobile_phone_number\n    mobile_phone_number_memo\n    phone_number\n    phone_number_memo\n    post_code\n    id\n    staff_type\n    name\n    name_furigana\n  }\n}\n"])));
var GET_USER = exports.GET_USER = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: String){\n  user(where: {id: {_eq: $id}}) {\n    address\n    address_2\n    address_3\n    admin_name\n    get_age\n    birthday\n    blood_type\n    blood_type_rh\n    entry_date\n    gender\n    has_child\n    has_mate\n    home_station\n    home_station_line\n    home_station_line_2\n    is_search\n    mobile_phone_number\n    mobile_phone_number_memo\n    phone_number\n    phone_number_memo\n    post_code\n    id\n    staff_type\n    name\n    name_furigana\n    display_information\n  }\n}\n"])));
var USERS_BY_IDS = exports.USERS_BY_IDS = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nquery($userIds: [String!]){\n  user(where: {id: {_in: $userIds}}) {\n    uid\n  }\n}\n"])));
var GET_CONTRACT = exports.GET_CONTRACT = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: Int){\n  user_file_link(where: {id: {_eq: $id}}) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    is_user_read\n    user{\n      name\n      name_furigana\n    }\n    user_storage_files(order_by: {order_by: asc}) {\n      name\n      read_at\n      order_by\n      url\n      id\n    }\n  }\n}\n"])));
var CONTRACTS_LIST_COUNT = exports.CONTRACTS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nquery ($staff_code: String, $searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean) {\n  user_file_link_aggregate(where: {\n    _and: [\n      {staff_code: {_eq: $staff_code}},\n      {title: {_ilike: $searchTitle}},\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {file_type: {_neq: 4}}\n    ]\n    }, order_by: {create_date: desc, is_acceptance: asc, title: asc}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CONTRACTS_LIST_QUERY = exports.CONTRACTS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nquery ($staff_code: String, $searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _and: [\n      {staff_code: {_eq: $staff_code}},\n      {title: {_ilike: $searchTitle}},\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {file_type: {_neq: 4}}\n    ]\n  }, order_by: {create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var CONTRACTS_LIST_QUERY_SORT_ASC = exports.CONTRACTS_LIST_QUERY_SORT_ASC = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nquery ($staff_code: String, $searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _and: [\n      {staff_code: {_eq: $staff_code}},\n      {title: {_ilike: $searchTitle}},\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {file_type: {_neq: 4}}\n    ]\n  }, order_by: {date_of_acceptance: asc_nulls_last, create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var CONTRACTS_LIST_QUERY_SORT_DESC = exports.CONTRACTS_LIST_QUERY_SORT_DESC = (0, _graphqlTag.default)(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\nquery ($staff_code: String, $searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _and: [\n      {staff_code: {_eq: $staff_code}},\n      {title: {_ilike: $searchTitle}},\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {file_type: {_neq: 4}}\n    ]\n  }, order_by: {date_of_acceptance: desc_nulls_last, create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var EMPLOYMENT_CONTRACTS_LIST_QUERY = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject13 || (_templateObject13 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $user_reading: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _or: [\n      {title: {_ilike: $searchTitle}},\n      {staff_code: {_ilike: $searchTitle}},\n      {user: {name: {_ilike: $searchTitle}}},\n      {user: {name_furigana: {_ilike: $searchTitle}}},\n    ],\n    _and: [\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {user_reading: {_eq: $user_reading}}\n    ]\n  }, order_by: {create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_DESC = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_DESC = (0, _graphqlTag.default)(_templateObject14 || (_templateObject14 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $user_reading: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _or: [\n      {title: {_ilike: $searchTitle}},\n      {staff_code: {_ilike: $searchTitle}},\n      {user: {name: {_ilike: $searchTitle}}},\n      {user: {name_furigana: {_ilike: $searchTitle}}},\n    ],\n    _and: [\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {user_reading: {_eq: $user_reading}}\n    ]\n  }, order_by: {date_of_acceptance: desc_nulls_last, create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_ASC = exports.EMPLOYMENT_CONTRACTS_LIST_QUERY_SORT_ASC = (0, _graphqlTag.default)(_templateObject15 || (_templateObject15 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $user_reading: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link (where: {\n    _or: [\n      {title: {_ilike: $searchTitle}},\n      {staff_code: {_ilike: $searchTitle}},\n      {user: {name: {_ilike: $searchTitle}}},\n      {user: {name_furigana: {_ilike: $searchTitle}}},\n    ],\n    _and: [\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {user_reading: {_eq: $user_reading}}\n    ]\n  }, order_by: {date_of_acceptance: asc_nulls_last, create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    user{\n      name\n      name_furigana\n    }\n  }\n}\n"])));
var EMPLOYMENT_CONTRACTS_LIST_COUNT = exports.EMPLOYMENT_CONTRACTS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject16 || (_templateObject16 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchTitle: String, $searchDocumentType: Int, $is_acceptance: Boolean, $user_reading: Boolean, $page_top: Int, $page_size: Int) {\n  user_file_link_aggregate (where: {\n    _or: [\n      {title: {_ilike: $searchTitle}},\n      {staff_code: {_ilike: $searchTitle}},\n      {user: {name: {_ilike: $searchTitle}}},\n      {user: {name_furigana: {_ilike: $searchTitle}}},\n    ],\n    _and: [\n      {file_type: {_eq: $searchDocumentType}},\n      {is_acceptance: {_eq: $is_acceptance}},\n      {user_reading: {_eq: $user_reading}}\n    ]\n  }, order_by: {create_date: desc, is_acceptance: asc, title: asc}, offset: $page_top, limit: $page_size) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var REQUEST_LIST_COUNT = exports.REQUEST_LIST_COUNT = (0, _graphqlTag.default)(_templateObject17 || (_templateObject17 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String, $workflow: Int, $user_reading: Boolean, $page_offset: Int, $page_limit: Int) {\n  user_file_link_aggregate:get_applications_for_admin_aggregate(args: {is_read: $user_reading, keyword: $searchWord, page_limit: $page_limit, page_offset: $page_offset, workflow_type: $workflow}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var REQUEST_LIST_QUERY = exports.REQUEST_LIST_QUERY = (0, _graphqlTag.default)(_templateObject18 || (_templateObject18 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String, $workflow: Int, $user_reading: Boolean, $page_offset: Int, $page_limit: Int) {\n  user_file_link: get_applications_for_admin(args: {is_read: $user_reading, keyword: $searchWord, page_limit: $page_limit, page_offset: $page_offset, workflow_type: $workflow}) {\n    id\n    title\n    staff_code\n    file_url\n    file_name\n    attach_file_type\n    file_type\n    create_date\n    is_acceptance\n    date_of_acceptance\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n    user_reading\n    is_user_read\n    user{\n      name\n      name_furigana\n    }\n    user_storage_files(order_by: {order_by: asc}) {\n      name\n      order_by\n      read_at\n    }\n  }\n}\n"])));
var USER_TYPE_LIST = exports.USER_TYPE_LIST = (0, _graphqlTag.default)(_templateObject19 || (_templateObject19 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  user(distinct_on: staff_type, order_by: {staff_type: desc}) {\n    staff_type\n  }\n}\n"])));
var SELECT_USER_QUERY = exports.SELECT_USER_QUERY = (0, _graphqlTag.default)(_templateObject20 || (_templateObject20 = (0, _taggedTemplateLiteral2.default)(["\nquery ($list: [String!]!){\n  user(where: {id: {_in: $list}}) {\n    id\n    name\n    staff_type\n    user_id: uid\n  }\n}\n"])));
var GET_REQUEST_READING = exports.GET_REQUEST_READING = (0, _graphqlTag.default)(_templateObject21 || (_templateObject21 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  user_file_link_aggregate(where: {\n    _and: [\n      {workflow: {_eq: 1}},\n      {file_type: {_eq: 4}},\n      ]\n  }\n    ) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var DELETE_CONTRACT_WITH_DELETED_ID = exports.DELETE_CONTRACT_WITH_DELETED_ID = (0, _graphqlTag.default)(_templateObject22 || (_templateObject22 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteContractWithDeletedAt($id: Int!) {\n  update_user_file_link_by_pk(pk_columns: {id: $id}, _set: {deleted_at: \"now()\"}) {\n    id\n  }\n}\n"])));