"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ADMIN_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject;
var ADMIN_QUERY = exports.ADMIN_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($uid: uuid) {\n  admin (where: {\n    _and: [ \n      {uid: {_eq: $uid}},\n    ]\n  }) {\n    id\n    uid\n    name\n    avatar\n    password\n    permission\n    has_chat_room\n    room_comment\n  }\n}\n"])));