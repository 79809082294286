"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddStaffMode = getAddStaffMode;
exports.getAdminId = getAdminId;
exports.getAdminUUId = getAdminUUId;
exports.getCallBackMode = getCallBackMode;
exports.getChatId = getChatId;
exports.getChatReadingCount = getChatReadingCount;
exports.getClientChatReadingCount = getClientChatReadingCount;
exports.getClientDocumentID = getClientDocumentID;
exports.getClientFileReadingCount = getClientFileReadingCount;
exports.getClientID = getClientID;
exports.getClientUID = getClientUID;
exports.getClientUpdateCount = getClientUpdateCount;
exports.getCompanyName = getCompanyName;
exports.getContractID = getContractID;
exports.getEntryReadingCount = getEntryReadingCount;
exports.getMessageMode = getMessageMode;
exports.getNewsID = getNewsID;
exports.getQaID = getQaID;
exports.getRecruitmentID = getRecruitmentID;
exports.getRecruitmentMessageID = getRecruitmentMessageID;
exports.getRecruitmentMessageReadingCount = getRecruitmentMessageReadingCount;
exports.getRecruitmentReadingCount = getRecruitmentReadingCount;
exports.getRequestDetailMode = getRequestDetailMode;
exports.getRoomId = getRoomId;
exports.getSelectDetailMode = getSelectDetailMode;
exports.getSelectStaff = getSelectStaff;
exports.getSiteUserID = getSiteUserID;
exports.getStaffCode = getStaffCode;
exports.getStaffID = getStaffID;
exports.getStaffMode = getStaffMode;
exports.getStaffName = getStaffName;
exports.getStaffRequestCount = getStaffRequestCount;
exports.getToken = getToken;
exports.removeAddStaffMode = removeAddStaffMode;
exports.removeAdminId = removeAdminId;
exports.removeAdminUUId = removeAdminUUId;
exports.removeCallBackMode = removeCallBackMode;
exports.removeChatId = removeChatId;
exports.removeChatReadingCount = removeChatReadingCount;
exports.removeClientChatReadingCount = removeClientChatReadingCount;
exports.removeClientDocumentID = removeClientDocumentID;
exports.removeClientFileReadingCount = removeClientFileReadingCount;
exports.removeClientID = removeClientID;
exports.removeClientUID = removeClientUID;
exports.removeClientUpdateCount = removeClientUpdateCount;
exports.removeCompanyName = removeCompanyName;
exports.removeContractID = removeContractID;
exports.removeEntryReadingCount = removeEntryReadingCount;
exports.removeMessageMode = removeMessageMode;
exports.removeNewsID = removeNewsID;
exports.removeQaID = removeQaID;
exports.removeRecruitmentID = removeRecruitmentID;
exports.removeRecruitmentMessageID = removeRecruitmentMessageID;
exports.removeRecruitmentMessageReadingCount = removeRecruitmentMessageReadingCount;
exports.removeRecruitmentReadingCount = removeRecruitmentReadingCount;
exports.removeRequestDetailMode = removeRequestDetailMode;
exports.removeRoomId = removeRoomId;
exports.removeSelectDetailMode = removeSelectDetailMode;
exports.removeSelectStaff = removeSelectStaff;
exports.removeSiteUserID = removeSiteUserID;
exports.removeStaffCode = removeStaffCode;
exports.removeStaffID = removeStaffID;
exports.removeStaffMode = removeStaffMode;
exports.removeStaffName = removeStaffName;
exports.removeStaffRequestCount = removeStaffRequestCount;
exports.removeToken = removeToken;
exports.setAddStaffMode = setAddStaffMode;
exports.setAdminId = setAdminId;
exports.setAdminUUId = setAdminUUId;
exports.setCallBackMode = setCallBackMode;
exports.setChatId = setChatId;
exports.setChatReadingCount = setChatReadingCount;
exports.setClientChatReadingCount = setClientChatReadingCount;
exports.setClientDocumentID = setClientDocumentID;
exports.setClientFileReadingCount = setClientFileReadingCount;
exports.setClientID = setClientID;
exports.setClientUID = setClientUID;
exports.setClientUpdateCount = setClientUpdateCount;
exports.setCompanyName = setCompanyName;
exports.setContractID = setContractID;
exports.setEntryReadingCount = setEntryReadingCount;
exports.setMessageMode = setMessageMode;
exports.setNewsID = setNewsID;
exports.setQaID = setQaID;
exports.setRecruitmentID = setRecruitmentID;
exports.setRecruitmentMessageID = setRecruitmentMessageID;
exports.setRecruitmentMessageReadingCount = setRecruitmentMessageReadingCount;
exports.setRecruitmentReadingCount = setRecruitmentReadingCount;
exports.setRequestDetailMode = setRequestDetailMode;
exports.setRoomId = setRoomId;
exports.setSelectDetailMode = setSelectDetailMode;
exports.setSelectStaff = setSelectStaff;
exports.setSiteUserID = setSiteUserID;
exports.setStaffCode = setStaffCode;
exports.setStaffID = setStaffID;
exports.setStaffMode = setStaffMode;
exports.setStaffName = setStaffName;
exports.setStaffRequestCount = setStaffRequestCount;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'fs_admin_token';
var AdminIdKey = 'fs_admin_id';
var AdminUUIdKey = 'fs_admin_uuid';
var StaffIdKey = 'fs_admin_staff_id';
var StaffCodeKey = 'fs_admin_staff_code';
var StaffRequestCountCodeKey = 'fs_admin_staff_request_count';
var CompanyNameKey = 'fs_admin_company_name';
var StaffNameKey = 'fs_admin_staff_name';
var ContractIDKey = 'fs_admin_contract_id';
var RecruitmentIdKey = 'fs_admin_recruitment_id';
var SelectDetailModeKey = 'fs_admin_select_detail_mode';
var RecruitmentMessageIdKey = 'fs_admin_recruitment_message_id';
var ClientIDKey = 'fs_admin_client_id';
var ClientUIDKey = 'fs_admin_client_uid';
var ClientDocumentIDKey = 'fs_admin_client_document_id';
var RoomIdKey = 'fs_admin_room_id';
var ChatIdKey = 'fs_admin_chat_id';
var SiteUserIdKey = 'fs_admin_siteuser_id';
var AddStaffModeKey = 'fs_admin_addstaff_mode';
var MessageModeKey = 'fs_admin_message_mode';
var RecruitmentReadingCountKey = 'fs_admin_RecruitmentReadingCount';
var ClientFileReadingCountKey = 'fs_admin_ClientFileReadingCount';
var RecruitmentMessageReadingCountKey = 'fs_admin_RecruitmentMessageReadingCount';
var ChatReadingCountKey = 'fs_admin_ChatReadingCount';
var ClientChatReadingCountKey = 'fs_admin_ClientChatReadingCount';
var ClientUpdateCountKey = 'fs_admin_ClientUpdateCount';
var SelectStaffListKey = 'fs_admin_SelectStaffList';
var NewsIDKey = 'fs_admin_NewsID';
var StaffModeKey = 'fs_StaffMode';
var CallBackModeKey = 'fs_admin_CallBackMode';
var RequestDetailModeKey = 'fs_admin_RequestDetailModeKey';
var QAIDKey = 'fs_admin_QAModeKey';
var EntryReadingCountKey = 'fs_admin_EntryReadingCount';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getAdminId() {
  return _jsCookie.default.get(AdminIdKey);
}
function setAdminId(token) {
  return _jsCookie.default.set(AdminIdKey, token);
}
function removeAdminId() {
  return _jsCookie.default.remove(AdminIdKey);
}
function getAdminUUId() {
  return _jsCookie.default.get(AdminUUIdKey);
}
function setAdminUUId(value) {
  return _jsCookie.default.set(AdminUUIdKey, value);
}
function removeAdminUUId() {
  return _jsCookie.default.remove(AdminUUIdKey);
}
function setRoomId(value) {
  return _jsCookie.default.set(RoomIdKey, value);
}
function getRoomId() {
  return _jsCookie.default.get(RoomIdKey);
}
function removeRoomId() {
  return _jsCookie.default.remove(RoomIdKey);
}
function setChatId(value) {
  return _jsCookie.default.set(ChatIdKey, value);
}
function getChatId() {
  return _jsCookie.default.get(ChatIdKey);
}
function removeChatId() {
  return _jsCookie.default.remove(ChatIdKey);
}
function setStaffID(value) {
  return _jsCookie.default.set(StaffIdKey, value);
}
function getStaffID() {
  return _jsCookie.default.get(StaffIdKey);
}
function removeStaffID() {
  return _jsCookie.default.remove(StaffIdKey);
}
function setCompanyName(value) {
  return _jsCookie.default.set(CompanyNameKey, value);
}
function setStaffCode(value) {
  return _jsCookie.default.set(StaffCodeKey, value);
}
function setStaffName(value) {
  return _jsCookie.default.set(StaffNameKey, value);
}
function getCompanyName() {
  return _jsCookie.default.get(CompanyNameKey);
}
function getStaffCode() {
  return _jsCookie.default.get(StaffCodeKey);
}
function getStaffName() {
  return _jsCookie.default.get(StaffNameKey);
}
function removeStaffCode() {
  return _jsCookie.default.remove(StaffCodeKey);
}
function removeCompanyName() {
  return _jsCookie.default.remove(CompanyNameKey);
}
function removeStaffName() {
  return _jsCookie.default.remove(StaffNameKey);
}
function setClientUID(value) {
  return _jsCookie.default.set(ClientUIDKey, value);
}
function getClientUID() {
  return _jsCookie.default.get(ClientUIDKey);
}
function removeClientUID() {
  return _jsCookie.default.remove(ClientUIDKey);
}
function setClientID(value) {
  return _jsCookie.default.set(ClientIDKey, value);
}
function getClientID() {
  return _jsCookie.default.get(ClientIDKey);
}
function removeClientID() {
  return _jsCookie.default.remove(ClientIDKey);
}
function setClientDocumentID(value) {
  return _jsCookie.default.set(ClientDocumentIDKey, value);
}
function getClientDocumentID() {
  return _jsCookie.default.get(ClientDocumentIDKey);
}
function removeClientDocumentID() {
  return _jsCookie.default.remove(ClientDocumentIDKey);
}
function setContractID(value) {
  return _jsCookie.default.set(ContractIDKey, value);
}
function getContractID() {
  return _jsCookie.default.get(ContractIDKey);
}
function removeContractID() {
  return _jsCookie.default.remove(ContractIDKey);
}
function setRecruitmentID(value) {
  return _jsCookie.default.set(RecruitmentIdKey, value);
}
function getRecruitmentID() {
  return _jsCookie.default.get(RecruitmentIdKey);
}
function removeRecruitmentID() {
  return _jsCookie.default.remove(RecruitmentIdKey);
}
function setSelectDetailMode(value) {
  return _jsCookie.default.set(SelectDetailModeKey, value);
}
function getSelectDetailMode() {
  return _jsCookie.default.get(SelectDetailModeKey);
}
function removeSelectDetailMode() {
  return _jsCookie.default.remove(SelectDetailModeKey);
}
function setRecruitmentMessageID(value) {
  return _jsCookie.default.set(RecruitmentMessageIdKey, value);
}
function getRecruitmentMessageID() {
  return _jsCookie.default.get(RecruitmentMessageIdKey);
}
function removeRecruitmentMessageID() {
  return _jsCookie.default.remove(RecruitmentMessageIdKey);
}
function setSiteUserID(value) {
  return _jsCookie.default.set(SiteUserIdKey, value);
}
function getSiteUserID() {
  return _jsCookie.default.get(SiteUserIdKey);
}
function removeSiteUserID() {
  return _jsCookie.default.remove(SiteUserIdKey);
}
function setAddStaffMode(value) {
  return _jsCookie.default.set(AddStaffModeKey, value);
}
function getAddStaffMode() {
  return _jsCookie.default.get(AddStaffModeKey);
}
function removeAddStaffMode() {
  return _jsCookie.default.remove(AddStaffModeKey);
}
function setMessageMode(value) {
  return _jsCookie.default.set(MessageModeKey, value);
}
function getMessageMode() {
  return _jsCookie.default.get(MessageModeKey);
}
function removeMessageMode() {
  return _jsCookie.default.remove(MessageModeKey);
}
function setRecruitmentReadingCount(value) {
  return _jsCookie.default.set(RecruitmentReadingCountKey, value);
}
function getRecruitmentReadingCount() {
  return _jsCookie.default.get(RecruitmentReadingCountKey);
}
function removeRecruitmentReadingCount() {
  return _jsCookie.default.remove(RecruitmentReadingCountKey);
}
function setClientFileReadingCount(value) {
  return _jsCookie.default.set(ClientFileReadingCountKey, value);
}
function getClientFileReadingCount() {
  return _jsCookie.default.get(ClientFileReadingCountKey);
}
function removeClientFileReadingCount() {
  return _jsCookie.default.remove(ClientFileReadingCountKey);
}
function setRecruitmentMessageReadingCount(value) {
  return _jsCookie.default.set(RecruitmentMessageReadingCountKey, value);
}
function getRecruitmentMessageReadingCount() {
  return _jsCookie.default.get(RecruitmentMessageReadingCountKey);
}
function removeRecruitmentMessageReadingCount() {
  return _jsCookie.default.remove(RecruitmentMessageReadingCountKey);
}
function setChatReadingCount(value) {
  return _jsCookie.default.set(ChatReadingCountKey, value);
}
function getChatReadingCount() {
  return _jsCookie.default.get(ChatReadingCountKey);
}
function removeChatReadingCount() {
  return _jsCookie.default.remove(ChatReadingCountKey);
}
function setClientUpdateCount(value) {
  return _jsCookie.default.set(ClientUpdateCountKey, value);
}
function getClientUpdateCount() {
  return _jsCookie.default.get(ClientUpdateCountKey);
}
function removeClientUpdateCount() {
  return _jsCookie.default.remove(ClientUpdateCountKey);
}
function setSelectStaff(value) {
  return _jsCookie.default.set(SelectStaffListKey, value);
}
function getSelectStaff() {
  return _jsCookie.default.get(SelectStaffListKey);
}
function removeSelectStaff() {
  return _jsCookie.default.remove(SelectStaffListKey);
}
function setNewsID(value) {
  return _jsCookie.default.set(NewsIDKey, value);
}
function getNewsID() {
  return _jsCookie.default.get(NewsIDKey);
}
function removeNewsID() {
  return _jsCookie.default.remove(NewsIDKey);
}
function setStaffMode(value) {
  return _jsCookie.default.set(StaffModeKey, value);
}
function getStaffMode() {
  return _jsCookie.default.get(StaffModeKey);
}
function removeStaffMode() {
  return _jsCookie.default.remove(StaffModeKey);
}
function setClientChatReadingCount(value) {
  return _jsCookie.default.set(ClientChatReadingCountKey, value);
}
function getClientChatReadingCount() {
  return _jsCookie.default.get(ClientChatReadingCountKey);
}
function removeClientChatReadingCount() {
  return _jsCookie.default.remove(ClientChatReadingCountKey);
}
function getStaffRequestCount() {
  return _jsCookie.default.get(StaffRequestCountCodeKey);
}
function setStaffRequestCount(value) {
  return _jsCookie.default.set(StaffRequestCountCodeKey, value);
}
function removeStaffRequestCount() {
  return _jsCookie.default.remove(StaffRequestCountCodeKey);
}
function getCallBackMode() {
  return _jsCookie.default.get(CallBackModeKey);
}
function setCallBackMode(value) {
  return _jsCookie.default.set(CallBackModeKey, value);
}
function removeCallBackMode() {
  return _jsCookie.default.remove(CallBackModeKey);
}
function getRequestDetailMode() {
  return _jsCookie.default.get(RequestDetailModeKey);
}
function setRequestDetailMode(value) {
  return _jsCookie.default.set(RequestDetailModeKey, value);
}
function removeRequestDetailMode() {
  return _jsCookie.default.remove(RequestDetailModeKey);
}
function setQaID(value) {
  return _jsCookie.default.set(QAIDKey, value);
}
function getQaID() {
  return _jsCookie.default.get(QAIDKey);
}
function removeQaID() {
  return _jsCookie.default.remove(QAIDKey);
}
function getEntryReadingCount() {
  return _jsCookie.default.get(EntryReadingCountKey);
}
function setEntryReadingCount(value) {
  return _jsCookie.default.set(EntryReadingCountKey, value);
}
function removeEntryReadingCount() {
  return _jsCookie.default.remove(EntryReadingCountKey);
}