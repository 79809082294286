var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" スタッフ詳細"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "id", label: "スタッフ番号", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "entry_date", label: "登録日", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "staff_type",
                label: "スタッフ区分",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: " 検索対象", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.displayStaffType(scope.row.is_search)) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "name", label: "氏名(漢字)", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name_furigana",
                label: "氏名(ｶﾅ)",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "gender", label: "性別", align: "center" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "生年月日", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.displayDateTime(
                              scope.row.birthday,
                              "YYYY年MM月DD日"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "get_age", label: "年齢", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "血液型", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.displayBloodType(
                              scope.row.blood_type,
                              scope.row.blood_type_rh
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "post_code", label: "郵便番号", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "address", label: "住所1", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "address_2", label: "住所2", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "address_3", label: "住所3", align: "center" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "home_station", label: "最寄駅", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "home_station_line",
                label: "沿線1",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "home_station_line_2",
                label: "沿線2",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "phone_number",
                label: "電話番号",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "phone_number_memo",
                label: "電話番号(備考)",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "address_2", label: "携帯電話", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "address_3",
                label: "携帯電話(備考)",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c("h3", { staticClass: "header-title" }, [_vm._v("略歴")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "name", label: "氏名", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name_furigana",
                label: "氏名（ふりがな）",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "birthday", label: "生年月日", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "age", label: "年齢", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "sex", label: "性別", align: "center" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "20%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "min-width": "20",
                prop: "post_code",
                label: "郵便番号",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "address", label: "現住所", align: "center" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "address_furigana",
                label: "現住所（ふりがな）",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "min-width": "8",
                prop: "final_education_year",
                label: "年",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "8",
                prop: "final_education_month",
                label: "月",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "final_education_exp",
                label: "最終学歴",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form[0].work_exp_list,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "min-width": "8",
                prop: "year",
                label: "年",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "8",
                prop: "month",
                label: "月",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "exp",
                label: "職歴（直近のもの）",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "資格", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("p", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.requirements)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "min-width": "35",
                prop: "transportation",
                label: "交通機関",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "15",
                prop: "working_days",
                label: "勤務希望日数",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "30",
                prop: "overtime",
                label: "残業",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "20",
                prop: "start_dt",
                label: "希望スタート日時",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("h5", { staticClass: "header-title" }, [_vm._v("勤務時間（約）")]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "commuting_time_hours",
                label: "時間",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "commuting_time_minutes",
                label: "分",
                align: "center",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("h5", { staticClass: "header-title" }, [
          _vm._v("備考欄（クライアント様には表示されません）"),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "備考", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("p", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.remarks)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-block" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" "),
            _vm.isNotDetail != false
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goToAddStaff()
                      },
                    },
                  },
                  [_vm._v("スタッフを追加")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.addDialogVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.addDialogVisible = $event
              },
            },
          },
          [
            _c("span", [_vm._v("スタッフを追加してもよろしいですか？")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.addDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.onAdd } }, [_vm._v("はい")]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }