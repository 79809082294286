"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_SECTION_PRIORITY = exports.UPDATE_SECTION = exports.UPDATE_QA_PRIORITY = exports.UPDATE_QA = exports.QA_PUBLISHE_ON = exports.QA_PUBLISHE_OFF = exports.DELETE_SECTION = exports.DELETE_QAS = exports.DELETE_FILES_IDS = exports.CREATE_QA_PDF = exports.CREATE_QA = exports.ADD_SECTION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var QA_PUBLISHE_ON = exports.QA_PUBLISHE_ON = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation qa_publishe_on(\n  $id: Int\n) {\n  update_qas(\n    where: {id: {_eq: $id}},\n    _set: {\n      published_at: \"now\"\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n    }  \n  }\n}\n"])));
var QA_PUBLISHE_OFF = exports.QA_PUBLISHE_OFF = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation qa_publishe_off(\n  $id: Int\n) {\n  update_qas(\n    where: {id: {_eq: $id}},\n    _set: {\n      published_at: null\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n    }  \n  }\n}\n"])));
var CREATE_QA = exports.CREATE_QA = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation createQas($section_id: Int, $title: String, $descriptions: String) {\n  insert_qas(\n    objects: {\n      title: $title,\n      descriptions: $descriptions,\n      section_id: $section_id,\n      published_at: \"now\"\n    }) {\n    affected_rows\n    returning {\n      id\n      title\n      descriptions\n      order_priority\n      section_id\n      published_at\n      created_at\n      updated_at\n    }\n  }\n}\n"])));
var UPDATE_QA = exports.UPDATE_QA = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateQas(\n  $id: Int,\n  $section_id: Int,\n  $title: String,\n  $descriptions: String\n) {\n  update_qas(\n    where: {id: {_eq: $id}},\n    _set: {\n      section_id: $section_id,\n      title: $title,\n      descriptions: $descriptions\n    }\n  ) {\n    affected_rows\n  }\n}\n"])));
var UPDATE_QA_PRIORITY = exports.UPDATE_QA_PRIORITY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateQas(\n  $id: Int,\n  $order_priority: Int\n) {\n  update_qas(\n    where: {id: {_eq: $id}},\n    _set: {\n      order_priority: $order_priority\n    }\n  ) {\n    affected_rows\n  }\n}\n"])));
var CREATE_QA_PDF = exports.CREATE_QA_PDF = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation createQas($objects: [qa_files_insert_input!]!) {\n  insert_qa_files(\n    objects: $objects\n  ) {\n    affected_rows\n    returning {\n      id\n      name\n      url\n      order_priority\n      qa_id\n      created_at\n      updated_at\n    }\n  }\n}\n"])));
var DELETE_FILES_IDS = exports.DELETE_FILES_IDS = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteFileIds($fileIds: [Int!]!){\n  delete_qa_files(where: {id: {_in: $fileIds}}) {\n    affected_rows\n  }\n}\n"])));
var ADD_SECTION = exports.ADD_SECTION = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nmutation addSection($objects: [qa_sections_insert_input!]!) {\n  insert_qa_sections(\n    objects: $objects\n  ) {\n    affected_rows\n    returning {\n      id\n      name\n      order_priority\n      created_at\n      updated_at\n    }\n  }\n}\n"])));
var UPDATE_SECTION = exports.UPDATE_SECTION = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateSection(\n  $id: Int,\n  $name: String\n) {\n  update_qa_sections(\n    where: {id: {_eq: $id}},\n    _set: {\n      name: $name\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      name\n      order_priority\n      created_at\n      updated_at\n    }\n  }\n}\n"])));
var UPDATE_SECTION_PRIORITY = exports.UPDATE_SECTION_PRIORITY = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateSection(\n  $id: Int,\n  $order_priority: Int\n) {\n  update_qa_sections(\n    where: {id: {_eq: $id}},\n    _set: {\n      order_priority: $order_priority\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      name\n      order_priority\n      created_at\n      updated_at\n    }\n  }\n}\n"])));
var DELETE_SECTION = exports.DELETE_SECTION = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteSection($id: Int) {\n  update_qas(\n    where: {section_id: {_eq: $id}},\n    _set: {\n      section_id: null\n    }    \n  ) {\n    affected_rows\n  }\n  delete_qa_sections(\n    where: {id: {_eq: $id}}\n  ) {\n    affected_rows\n  }\n}\n"])));
var DELETE_QAS = exports.DELETE_QAS = (0, _graphqlTag.default)(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteSection($id: Int) {\n  update_qas(\n    where: {id: {_eq: $id}},\n    _set: {\n      section_id: null\n    }    \n  ) {\n    affected_rows\n  }\n  delete_qa_files(\n    where: {qa_id: {_eq: $id}}\n  ) {\n    affected_rows\n  }\n  delete_qas(\n    where: {id: {_eq: $id}}\n  ) {\n    affected_rows\n  }  \n}\n"])));