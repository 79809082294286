var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "userForm",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.userName,
                  expression: "showForm.userName",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "userName",
                        label: "フルネーム",
                        rules: _vm.rules.userName,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "山田太郎" },
                        model: {
                          value: _vm.form.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userName", $$v)
                          },
                          expression: "form.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.email,
                  expression: "showForm.email",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "email",
                        label: "メールアドレス",
                        rules: _vm.rules.email,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "yamada@staff.com" },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.password,
                  expression: "showForm.password",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "password",
                            label: "パスワード",
                            rules: _vm.rules.password,
                          },
                        },
                        [
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "英数字8文字以上",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.capsTooltip = false
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.checkCapslock($event)
                              },
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.password,
                  expression: "showForm.password",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "confirmedPassword",
                            label: "パスワード確認",
                            rules: _vm.rules.confirmedPassword,
                          },
                        },
                        [
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "パスワード確認",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.capsTooltip = false
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.checkCapslock($event)
                              },
                            },
                            model: {
                              value: _vm.form.confirmedPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "confirmedPassword", $$v)
                              },
                              expression: "form.confirmedPassword",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.permission,
                  expression: "showForm.permission",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "permission",
                        label: "権限",
                        rules: _vm.rules.permission,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "value-key": "id",
                            placeholder: "権限を選択してください",
                          },
                          model: {
                            value: _vm.form.permission,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "permission", $$v)
                            },
                            expression: "form.permission",
                          },
                        },
                        _vm._l(_vm.permissionOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.hasChatRoom,
                  expression: "showForm.hasChatRoom",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.form.hasChatRoom,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hasChatRoom", $$v)
                        },
                        expression: "form.hasChatRoom",
                      },
                    },
                    [_vm._v("チャットルームを持つ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.icon,
                  expression: "showForm.icon",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 15 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "アイコン" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            drag: "",
                            accept: " .jpg, .jpeg, .png",
                            action: "",
                            limit: 1,
                            "on-change": _vm.handleAdd,
                            "on-remove": _vm.handleRemove,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                            "list-type": "picture",
                            "auto-upload": false,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("ファイルをドロップする"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.orgImgData.length > 0 && _vm.fileList.length === 0
                        ? _c("div", [
                            _c("img", {
                              staticClass: "preview",
                              attrs: { src: _vm.orgImgData },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.roomComment,
                  expression: "showForm.roomComment",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "roomComment", label: "ルームコメント" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "お気軽にどうぞ！" },
                        model: {
                          value: _vm.form.roomComment,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roomComment", $$v)
                          },
                          expression: "form.roomComment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-block" },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backToUserIndex")
                        },
                      },
                    },
                    [_vm._v("戻る")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(_vm._s(_vm.submitBtnName))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }