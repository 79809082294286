var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width,
        "close-on-click-modal": _vm.closeOnClickModal,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _vm.isConfirm()
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.handleNo } },
                [_vm._v(_vm._s(_vm.noText))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleYes } },
                [_vm._v(_vm._s(_vm.yesText))]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOK } },
                [_vm._v(_vm._s(_vm.okText))]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }