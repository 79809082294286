"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.function.name");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    control the page roles (you can set multiple roles)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 noCache: true                if set true, the page will no be cached(default is false)
 affix: true                  if set true, the tag will affix in the tags-view
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/',
  component: _layout.default,
  redirect: '/staff',
  name: 'StaffInformation',
  meta: {
    title: 'スタッフ情報',
    roles: ['admin', 'editor'] // you can set roles in root nav
  },
  children: [{
    path: 'staff',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/index'));
      });
    },
    name: 'StaffIndex',
    meta: {
      title: 'スタッフ一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: 'request_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/request_index'));
      });
    },
    name: 'RequestIndex',
    meta: {
      title: '申請書類一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: 'employment_contract_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/employment_contract_index'));
      });
    },
    name: 'EmploymentContractIndex',
    meta: {
      title: '雇用契約書一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: 'staff_select',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/staff_select'));
      });
    },
    name: 'StaffSelect',
    hidden: true,
    meta: {
      title: 'スタッフ選択',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: 'multiple_staff_contract_select',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/multiple_staff_contract_select'));
      });
    },
    name: 'StaffMultipleSelect',
    hidden: true,
    meta: {
      title: 'スタッフ選択',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/csv',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/csv'));
      });
    },
    name: 'StaffCreate',
    meta: {
      title: 'スタッフCSV取込み',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/detail'));
      });
    },
    name: 'StaffDetail',
    hidden: true,
    meta: {
      title: 'スタッフ詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/select_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/select_detail'));
      });
    },
    name: 'SelectDetail',
    hidden: true,
    meta: {
      title: 'スタッフ詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/contract_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/contract_index'));
      });
    },
    name: 'ContractIndex',
    hidden: true,
    meta: {
      title: '電子契約書一覧',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/add_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/add_contract'));
      });
    },
    name: 'AddContract',
    hidden: true,
    meta: {
      title: '電子契約書の追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/add_employment_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/add_employment_contract'));
      });
    },
    name: 'AddEmploymentContract',
    hidden: true,
    meta: {
      title: '雇用契約書の追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/detail_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/detail_contract'));
      });
    },
    name: 'DetailContract',
    hidden: true,
    meta: {
      title: '電子書類の詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/detail_request',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/detail_request'));
      });
    },
    name: 'DetailRequest',
    hidden: true,
    meta: {
      title: '申請書類',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/staff/add_multiple_staff_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/staff/add_multiple_staff_contract'));
      });
    },
    name: 'AddMultipleStaffContract',
    hidden: true,
    meta: {
      title: '雇用契約書の追加 (複数人)',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }]
},
// 求人情報
// - 求人情報一覧
// - 求人情報詳細
// - 求人情報新規作成/編集
// - 応募者一覧

{
  path: '/jobs',
  component: _layout.default,
  redirect: '/jobs',
  name: 'JobsInformation',
  meta: {
    title: '求人情報',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/jobs/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/jobs/index'));
      });
    },
    name: 'JobsIndex',
    meta: {
      title: '求人情報一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/jobs/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/jobs/create'));
      });
    },
    name: 'JobsCreate',
    meta: {
      title: '求人情報新規作成',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/jobs/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/jobs/edit'));
      });
    },
    name: 'JobsEdit',
    hidden: true,
    meta: {
      title: '求人情報編集',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/jobs/applicant',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/jobs/applicant'));
      });
    },
    name: 'JobsApplicant',
    hidden: true,
    meta: {
      title: '応募者一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/users',
  component: _layout.default,
  redirect: '/users',
  name: 'AdministratorInformation',
  meta: {
    title: '管理者情報',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/users/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/user/index'));
      });
    },
    name: 'UserIndex',
    meta: {
      title: '管理者一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/users/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/user/create'));
      });
    },
    name: 'UserCreate',
    meta: {
      title: '管理者新規作成',
      roles: ['admin']
    }
  }, {
    path: '/users/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/user/edit'));
      });
    },
    name: 'UserEdit',
    hidden: true,
    meta: {
      title: '管理者編集',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/users/mypage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/user/mypage'));
      });
    },
    name: 'AdminInfo',
    hidden: true,
    meta: {
      title: 'アカウント情報',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/client',
  component: _layout.default,
  redirect: '/client',
  name: 'ClientInformation',
  meta: {
    title: 'クライアント情報',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/client/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client/index'));
      });
    },
    name: 'ClientIndex',
    meta: {
      title: 'クライアント一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client/create'));
      });
    },
    name: 'ClientCreate',
    meta: {
      title: 'クライアント新規作成',
      roles: ['admin']
    }
  }, {
    path: '/client/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client/edit'));
      });
    },
    name: 'ClientEdit',
    hidden: true,
    meta: {
      title: 'クライアント編集',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/client/chat_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client/chat_index'));
      });
    },
    name: 'ChatClientIndex',
    meta: {
      title: 'チャット ',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client/chat_client'));
      });
    },
    name: 'ChatClient',
    hidden: true,
    meta: {
      title: 'クライアントチャット',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/index'));
      });
    },
    name: 'RecruitmentIndex',
    meta: {
      title: '求人エントリー',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/edit'));
      });
    },
    name: 'RecruitmentEdit',
    hidden: true,
    meta: {
      title: '求人エントリー詳細',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/recruitment/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/detail'));
      });
    },
    name: 'RecruitmentDetail',
    hidden: true,
    meta: {
      title: '求人エントリー詳細',
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/recruitment/message',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/message'));
      });
    },
    name: 'RecruitmentMessage',
    hidden: true,
    meta: {
      title: 'メッセージルーム',
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: 'client_document',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/index'));
      });
    },
    name: 'ClientDocumentIndex',
    meta: {
      title: 'クライアント書類管理',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/detail_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/detail_contract'));
      });
    },
    name: 'ClientDetailContract',
    hidden: true,
    meta: {
      title: '個別契約書詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/contract_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/contract_index'));
      });
    },
    name: 'ClientContractIndex',
    hidden: true,
    meta: {
      title: '個別契約書一覧',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/add_contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/add_contract'));
      });
    },
    name: 'AddClientContract',
    hidden: true,
    meta: {
      title: '個別契約書の追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/detail_invoice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/detail_invoice'));
      });
    },
    name: 'ClientDetailInvoice',
    hidden: true,
    meta: {
      title: '請求書詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/invoice_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/invoice_index'));
      });
    },
    name: 'ClientInvoiceIndex',
    hidden: true,
    meta: {
      title: '請求書確認一覧',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/add_invoice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/add_invoice'));
      });
    },
    name: 'AddClientInvoice',
    hidden: true,
    meta: {
      title: '請求書の追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/detail_attendance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/detail_attendance'));
      });
    },
    name: 'ClientDetailAttendance',
    hidden: true,
    meta: {
      title: '勤怠情報詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/attendance_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/attendance_index'));
      });
    },
    name: 'ClientAttendanceIndex',
    hidden: true,
    meta: {
      title: '勤怠情報一覧',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/labor_notice_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/labor_notice_index'));
      });
    },
    name: 'LaborNoticeIndex',
    hidden: true,
    meta: {
      title: '労働通知書一覧',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/add_labor_notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/add_labor_notice'));
      });
    },
    name: 'AddLaborNotice',
    hidden: true,
    meta: {
      title: '労働通知書の追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/client_document/detail_labor_notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/client_document/detail_labor_notice'));
      });
    },
    name: 'DetailLaborNotice',
    hidden: true,
    meta: {
      title: '労働通知書詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/qa_index',
  component: _layout.default,
  redirect: '/qa_index',
  children: [{
    path: '/qa_index/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/qa_index/index'));
      });
    },
    name: 'QAIndex',
    meta: {
      title: 'Q&A一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/qa_index/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/qa_index/edit'));
      });
    },
    name: 'QAEdit',
    hidden: true,
    meta: {
      title: 'Q&A詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/qa_index/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/qa_index/create'));
      });
    },
    name: 'QACreate',
    hidden: true,
    meta: {
      title: 'Q&A新規作成',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/qa_index/secstion_add',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/qa_index/secstion_add'));
      });
    },
    name: 'QASectionAdd',
    hidden: true,
    meta: {
      title: 'セクション追加',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/site_user',
  component: _layout.default,
  redirect: '/site_user',
  name: 'SiteUser',
  meta: {
    title: 'サイトユーザー情報',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/site_user/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/site_user/index'));
      });
    },
    name: 'SiteUserIndex',
    meta: {
      title: 'サイトユーザー一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/site_user/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/site_user/create'));
      });
    },
    name: 'SiteUserCreate',
    meta: {
      title: 'サイトユーザー新規作成',
      roles: ['admin']
    }
  }, {
    path: '/site_user/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/site_user/edit'));
      });
    },
    name: 'SiteUserEdit',
    hidden: true,
    meta: {
      title: 'サイトユーザー編集',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/site_user/room_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/site_user/room_index'));
      });
    },
    name: 'RoomIndex',
    hidden: true,
    meta: {
      title: 'スタッフ一覧',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
router.beforeEach(function (to, from, next) {
  switch (to.name) {
    case 'JobsEdit':
      if (to.params.jobId) {
        _jsCookie.default.set('jobId', to.params.jobId);
      } else {
        var jobIdFromCookie = _jsCookie.default.get('jobId');
        if (jobIdFromCookie) {
          to.params.jobId = jobIdFromCookie;
        } else {
          console.log('to.params.jobId', to.params.jobId);
          next('/jobs/index');
        }
      }
      break;
    case 'JobsIndex':
    case 'JobsCreate':
      _jsCookie.default.remove('jobId');
      break;
  }
  next();
});
var _default = exports.default = router;