var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" スタッフ一覧"),
          ],
          1
        ),
        _vm._v(" "),
        _c("RoomIndexFilter"),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.chat_users, stripe: "", fit: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "owner.name",
                label: "スタッフ一覧",
                align: "left",
                "min-width": "75",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-block" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goToAddUser()
                  },
                },
              },
              [_vm._v("スタッフ選択")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }