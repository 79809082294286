"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WITHDRAW_CONTRACT = exports.UPDATE_WITHDRAW_CONTRACT = exports.UPDATE_USER_DISPINFO = exports.UPDATE_USER = exports.UPDATE_REQUEST_WORKFLOW_SENDFILE = exports.UPDATE_REQUEST_WORKFLOW_CHEKING = exports.UPDATE_REQUEST_REMARKS = exports.UPDATE_CONTRACT_RELEASE_PLUS_DATE = exports.UPDATE_CONTRACT_RELEASE = exports.CREATE_USERS = exports.CREATE_CONTRACT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
var UPDATE_USER = exports.UPDATE_USER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateUser(\n  $id: String,\n  $name: String,\n  $name_furigana: String,\n  $post_code: String,\n  $address: String,\n  $phone_number: String,\n  $phone_number_memo: String,\n  $mobile_phone_number: String,\n  $mobile_phone_number_memo: String\n) {\n  update_user(\n    where: {id: {_eq: $id}},\n    _set: {\n      name: $name,\n      name_furigana: $name_furigana,\n      post_code: $post_code,\n      address: $address,\n      phone_number: $phone_number,\n      phone_number_memo: $phone_number_memo,\n      mobile_phone_number: $mobile_phone_number,\n      mobile_phone_number_memo: $mobile_phone_number_memo\n    }\n  ) {\n    affected_rows,\n    returning {\n      address\n      address_2\n      address_3\n      admin_name\n      age\n      birthday\n      blood_type\n      blood_type_rh\n      entry_date\n      gender\n      has_child\n      has_mate\n      home_station\n      home_station_line\n      home_station_line_2\n      is_search\n      mobile_phone_number\n      mobile_phone_number_memo\n      phone_number\n      phone_number_memo\n      post_code\n      id\n      staff_type\n      name\n      name_furigana\n      display_information\n    }  \n  }\n}\n"])));
var UPDATE_USER_DISPINFO = exports.UPDATE_USER_DISPINFO = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateUserDispinfo(\n  $id: String,\n  $display_information: String\n) {\n  update_user(\n    where: {id: {_eq: $id}},\n    _set: {\n      display_information: $display_information\n    }\n  ) {\n    affected_rows,\n    returning {\n      address\n      address_2\n      address_3\n      admin_name\n      age\n      birthday\n      blood_type\n      blood_type_rh\n      entry_date\n      gender\n      has_child\n      has_mate\n      home_station\n      home_station_line\n      home_station_line_2\n      is_search\n      mobile_phone_number\n      mobile_phone_number_memo\n      phone_number\n      phone_number_memo\n      post_code\n      id\n      staff_type\n      name\n      name_furigana\n      display_information\n    }  \n  }\n}\n"])));
var CREATE_USERS = exports.CREATE_USERS = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation createUsers($objects: [user_insert_input!]!) {\n  insert_user(\n    objects: $objects,\n    on_conflict: {\n      constraint:  user_id_key,\n      update_columns: [\n        entry_date,\n        staff_type,\n        is_search,\n        name_furigana,\n        site_user_name,\n        admin_name\n        name,\n        gender,\n        has_mate,\n        has_child,\n        birthday,\n        age,\n        blood_type,\n        blood_type_rh,\n        post_code,\n        address,\n        address_2,\n        address_3,\n        home_station,\n        home_station_line,\n        home_station_line_2,\n        phone_number,\n        phone_number_memo,\n        mobile_phone_number,\n        mobile_phone_number_memo\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      uid\n      name_furigana\n      name\n      site_user_name\n    }\n  }\n}\n"])));
var CREATE_CONTRACT = exports.CREATE_CONTRACT = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation createUsers($objects: [user_file_link_insert_input!]!) {\n  insert_user_file_link(\n    objects: $objects\n  ) {\n    affected_rows\n    returning {\n      id\n      title\n      file_url\n      file_name\n      attach_file_type\n      file_type\n      staff_code\n      create_date\n      is_acceptance\n      date_of_acceptance\n    }\n  }\n}\n"])));
var UPDATE_CONTRACT_RELEASE = exports.UPDATE_CONTRACT_RELEASE = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContract(\n  $id: Int,\n  $is_release: Boolean\n) {\n  update_user_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      is_release: $is_release\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      file_type\n      file_url\n      staff_code\n      is_acceptance\n      title\n      create_date\n      date_of_acceptance\n      attach_file_type\n      file_name\n      is_release\n    }  \n  }\n}\n"])));
var UPDATE_CONTRACT_RELEASE_PLUS_DATE = exports.UPDATE_CONTRACT_RELEASE_PLUS_DATE = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContract(\n  $id: Int,\n  $is_release: Boolean\n) {\n  update_user_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      is_release: $is_release,\n      release_date: \"now\"\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      file_type\n      file_url\n      staff_code\n      is_acceptance\n      title\n      create_date\n      date_of_acceptance\n      attach_file_type\n      file_name\n      is_release\n    }  \n  }\n}\n"])));
var WITHDRAW_CONTRACT = exports.WITHDRAW_CONTRACT = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateContract($id: Int!) {\n  update_user_file_link_by_pk(_set: {workflow: 7}, pk_columns: {id: $id}) {\n    id\n    file_type\n    file_url\n    staff_code\n    is_acceptance\n    title\n    create_date\n    date_of_acceptance\n    attach_file_type\n    file_name\n    is_release\n    release_date\n    reading_flag\n    request_message\n    workflow\n    remarks\n  }\n}\n\n\n"])));
var UPDATE_REQUEST_WORKFLOW_CHEKING = exports.UPDATE_REQUEST_WORKFLOW_CHEKING = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContract(\n  $id: Int,\n  $workflow: Int,\n  $remarks: String\n) {\n  update_user_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      workflow: $workflow,\n      remarks: $remarks\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      file_type\n      file_url\n      staff_code\n      is_acceptance\n      title\n      create_date\n      date_of_acceptance\n      attach_file_type\n      file_name\n      is_release\n      release_date\n      reading_flag\n      request_message\n      workflow\n      remarks\n    }  \n  }\n}\n"])));

// 機能[Task] #18505【要望5】送信後にPDFの編集削除を可能にする機能。
var UPDATE_WITHDRAW_CONTRACT = exports.UPDATE_WITHDRAW_CONTRACT = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateWithdrawContract($id: Int!, $remarks: String, $workflow: Int!, $delete_user_storage_file_ids: [Int!]!, $user_storage_files: [user_storage_files_insert_input!]!, $title: String, $file_url: String, $attach_file_type: Int, $file_name: String, $release_date: timestamptz, $is_release: Boolean) {\n  update_user_file_link_by_pk(pk_columns: {id: $id}, _set: {remarks: $remarks, workflow: $workflow, title: $title, file_url: $file_url, attach_file_type: $attach_file_type, file_name: $file_name, release_date: $release_date, is_release: $is_release}) {\n    id\n  }\n  delete_user_storage_files(where: {id: {_in: $delete_user_storage_file_ids}}) {\n    affected_rows\n  }\n  insert_user_storage_files(objects: $user_storage_files) {\n    affected_rows\n  }\n}\n\n"])));
var UPDATE_REQUEST_WORKFLOW_SENDFILE = exports.UPDATE_REQUEST_WORKFLOW_SENDFILE = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContract(\n  $id: Int,\n  $workflow: Int,\n  $title: String,\n  $remarks: String,\n  $file_name: String,\n  $file_url: String,\n  $attach_file_type: Int,\n  $objects: [user_storage_files_insert_input!]!\n) {\n  update_user_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      workflow: $workflow,\n      title: $title,\n      remarks: $remarks,\n      file_name: $file_name,\n      file_url: $file_url,\n      attach_file_type: $attach_file_type,\n      is_release: true,\n      release_date: \"now\"\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      file_type\n      file_url\n      staff_code\n      is_acceptance\n      title\n      create_date\n      date_of_acceptance\n      attach_file_type\n      file_name\n      is_release\n      release_date\n      reading_flag\n      request_message\n      workflow\n      remarks\n    }  \n  }\n  insert_user_storage_files(objects: $objects) {\n    affected_rows\n    returning {\n      name\n    }\n  }\n}\n"])));
var UPDATE_REQUEST_REMARKS = exports.UPDATE_REQUEST_REMARKS = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContract(\n  $id: Int,\n  $remarks: String\n) {\n  update_user_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      remarks: $remarks\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      file_type\n      file_url\n      staff_code\n      is_acceptance\n      title\n      create_date\n      date_of_acceptance\n      attach_file_type\n      file_name\n      is_release\n      release_date\n      reading_flag\n      request_message\n      workflow\n      remarks\n    }  \n  }\n}\n"])));