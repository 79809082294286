"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_NEWS_RESERVE = exports.UPDATE_NEWS = exports.INSERT_NEWS_DISTRIBUTIONS = exports.DELETE_NEWS_DISTRIBUTIONS = exports.CREATE_NEWS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var CREATE_NEWS = exports.CREATE_NEWS = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation createNews(\n  $title: String,\n  $description: String,\n  $publishedDateTime: timestamptz,\n  $isPublishNotice: Boolean,\n  $distributionType: Int,\n  $image: String,\n  $hasNewsSurvey: Boolean,\n  $expirationDateTime: timestamptz,\n  $is_published: Boolean\n) {\n  insert_news(objects: {\n    title: $title,\n    description: $description,\n    published_at: $publishedDateTime,\n    push_notification: $isPublishNotice,\n    has_news_survey: $hasNewsSurvey,\n    distribution_type: $distributionType,\n    image_url: $image,\n    expiration_at: $expirationDateTime,\n    is_published: $is_published\n  }) {\n    affected_rows\n    returning {\n      id\n      title\n      description\n      published_at\n      push_notification\n      distribution_type\n      image_url\n      has_news_survey\n      expiration_at\n      is_publish_reserved\n      is_published\n    }\n  }\n}\n"])));
var UPDATE_NEWS = exports.UPDATE_NEWS = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateNews(\n  $id: Int,\n  $title: String,\n  $description: String,\n  $publishedDateTime: timestamptz,\n  $isPublishNotice: Boolean,\n  $distributionType: Int,\n  $image: String,\n  $hasNewsSurvey: Boolean,\n  $expirationDateTime: timestamptz,\n  $is_published: Boolean\n) {\n  update_news(\n    where: {id: {_eq: $id}},\n    _set: {\n      title: $title,\n      description: $description,\n      published_at: $publishedDateTime,\n      push_notification: $isPublishNotice\n      has_news_survey: $hasNewsSurvey,\n      distribution_type: $distributionType,\n      image_url: $image,\n      expiration_at: $expirationDateTime\n      is_published: $is_published\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      title\n      description\n      published_at\n      push_notification\n      distribution_type\n      image_url\n      has_news_survey\n      expiration_at\n      is_publish_reserved\n      is_published\n    }\n  }\n}\n"])));
var UPDATE_NEWS_RESERVE = exports.UPDATE_NEWS_RESERVE = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateNews(\n  $id: Int,\n  $isPublishReserved: Boolean,\n) {\n  update_news(\n    where: {id: {_eq: $id}},\n    _set: {\n      is_publish_reserved: $isPublishReserved,\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      title\n      description\n      published_at\n      push_notification\n      distribution_type\n      image_url\n      has_news_survey\n      expiration_at\n      is_publish_reserved\n    }\n  }\n}\n"])));
var INSERT_NEWS_DISTRIBUTIONS = exports.INSERT_NEWS_DISTRIBUTIONS = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation insertNewsDistributions(\n  $newsId: Int,\n  $objects: [news_distributions_insert_input!]!\n) {\n  delete_news_distributions(\n    where: {news_id: {_eq: $newsId}}\n  ) {\n    affected_rows\n  }\n  insert_news_distributions(\n    objects: $objects\n  ) {\n    affected_rows\n    returning {\n      news_id\n      user_id\n      user {\n        name\n        staff_type\n        id\n      }\n    }\n  }\n}\n"])));
var DELETE_NEWS_DISTRIBUTIONS = exports.DELETE_NEWS_DISTRIBUTIONS = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation deletetNewsDistributions($newsId: Int) {\n  delete_news_distributions(\n    where: {news_id: {_eq: $newsId}}\n  ) {\n    affected_rows\n  }\n}\n"])));