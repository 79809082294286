var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("h3", { staticClass: "header-title" }, [
          _vm._v("スタッフCSV取り込み"),
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          { attrs: { "label-position": "top", "label-width": "120px" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { md: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "csvファイル" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-csv",
                            attrs: {
                              drag: "",
                              action: "csvImport",
                              "file-list": _vm.fileList,
                              limit: 1,
                              "auto-upload": false,
                              "on-change": _vm.handleChange,
                              "on-exceed": _vm.handleExceed,
                              "on-remove": _vm.handleRemove,
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v("ファイルをドロップする"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "action-block" },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "midButton",
                        attrs: { loading: _vm.loading, type: "primary" },
                        on: { click: _vm.onUploadCsv },
                      },
                      [_vm._v("取り込み")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }