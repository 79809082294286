var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container clearfix" }, [
    _c("div", { staticClass: "chat" }, [
      _c("hr", { staticClass: "divider" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chat-history" },
        [
          _c(
            "ul",
            { ref: "chatHistory" },
            [
              _vm._l(_vm.message, function (msg, msgIndex) {
                return _c(
                  "li",
                  { key: msgIndex, staticClass: "clearfix" },
                  [
                    msg.user_type === 2
                      ? [
                          _c(
                            "div",
                            { staticClass: "message-data align-right" },
                            [
                              _c("span", { staticClass: "message-data-time" }, [
                                _vm._v(
                                  _vm._s(_vm.displayMsgTime(msg.create_date))
                                ),
                              ]),
                              _vm._v("    \n              "),
                              _c("span", { staticClass: "message-data-name" }, [
                                _vm._v(_vm._s(msg.client_user[0].company_name)),
                              ]),
                              _vm._v(" "),
                              _c("i", { staticClass: "fa fa-circle company" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "message charge-message float-right",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(msg.message) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c("div", { staticClass: "message-data" }, [
                            _c("span", { staticClass: "message-data-name" }, [
                              _c("i", { staticClass: "fa fa-circle online" }),
                              _vm._v(" " + _vm._s(msg.admin[0].name)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "message-data-time" }, [
                              _vm._v(
                                _vm._s(_vm.displayMsgTime(msg.create_date))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "message staff-message" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(msg.message) +
                                "\n            "
                            ),
                          ]),
                        ],
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm._m(0),
            ],
            2
          ),
          _vm._v(" "),
          _c("back-to-top", {
            attrs: {
              "custom-style": _vm.baackToTopStyle,
              "visibility-height": 300,
              "back-position": 50,
              "transition-name": "fade",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chat-message clearfix chat-form", class: _vm.classObj },
        [
          _c("hr", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "el-form",
            [
              _c("div", [
                _c("div", { staticClass: "chat-info inline float-left" }, [
                  _c("div", { staticClass: "textColor" }, [
                    _vm._v("送信先（会社）：" + _vm._s(_vm.company_name)),
                    _c("br"),
                  ]),
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "divider" }),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { width: "100%" }, attrs: { prop: "message" } },
                [
                  _c("el-input", {
                    staticClass: "message-to-send",
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      size: "large",
                      placeholder: "メッセージを入力してください",
                      wrap: "hard",
                    },
                    model: {
                      value: _vm.inputMessage,
                      callback: function ($$v) {
                        _vm.inputMessage = $$v
                      },
                      expression: "inputMessage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSendMessage } },
            [_vm._v("送信")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.onBack } }, [_vm._v("戻る")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("i", { staticClass: "fa fa-circle online" }),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-circle online",
        staticStyle: { color: "#AED2A6" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }