"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_LOGINID = exports.GET_CLIENT_UPDATE_INFO_COUNT = exports.GET_CLIENT_FILE_READING = exports.GET_CLIENT_CONTRACT = exports.GET_CLIENT_CHAT_COUNT = exports.DELETE_CLIENT_CONTRACT_WITH_DELETED_ID = exports.CLIENT_QUERY = exports.CLIENTS_LIST_QUERY = exports.CLIENTS_LIST_COUNT = exports.CLIENTS_LABOR_NOTICE_LIST_COUNT = exports.CLIENTS_CONTRACT_LIST_QUERY = exports.CLIENTS_CONTRACT_LIST_COUNT_EX = exports.CLIENTS_CONTRACT_LIST_COUNT = exports.CLIENTS_ATTENDANCE_LIST_QUERY = exports.CLIENTS_ATTENDANCE_LIST_COUNT = exports.CHAT_MESSAGES_COUNT = exports.CHAT_CLIENT_ROOM_QUERY = exports.CHAT_CLIENT_MESSAGE_QUERY = exports.CHAT_CLIENTS_QUERY_EX = exports.CHAT_CLIENTS_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;
var CLIENT_QUERY = exports.CLIENT_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($uid: uuid) {\n  client_user(where: {uid: {_eq: $uid}}) {\n    id\n    uid\n    company_name\n    ceo_name\n    zip_code\n    address1\n    address2\n    phone_number\n    fax_number\n    mobile_phone\n    mail_address\n    occupation\n    business_content\n    login_id\n    login_password\n    update_info\n    is_login\n    created_at\n    updated_at\n  }\n}\n"])));
var CLIENTS_LIST_QUERY = exports.CLIENTS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String, $page_top: Int, $page_size: Int) {\n  client_user(order_by: {id: asc, update_info: asc_nulls_last}, where: {_or: [{company_name: {_ilike: $search_word}}, {address1: {_ilike: $search_word}}, {phone_number: {_ilike: $search_word}}, {mail_address: {_ilike: $search_word}}]}, offset: $page_top, limit: $page_size) {\n    id\n    uid\n    company_name\n    ceo_name\n    zip_code\n    address1\n    address2\n    phone_number\n    fax_number\n    mobile_phone\n    mail_address\n    occupation\n    business_content\n    login_id\n    login_password\n    is_login\n    created_at\n    updated_at\n    update_info\n    # \u500B\u5225\u5951\u7D04\u66F8\n    contract: client_file_link_aggregate(where: {admin_reading_flag: {_eq: true}, file_type: {_eq: 1}}) {\n      aggregate {\n        count(columns: admin_reading_flag)\n      }\n    }\n    # \u4E8B\u524D\u78BA\u8A8D\u8ACB\u6C42\u66F8\n    invoice: client_file_link_aggregate(where: {admin_reading_flag: {_eq: true}, file_type: {_eq: 2}}) {\n      aggregate {\n        count(columns: admin_reading_flag)\n      }\n    }\n    # \u52E4\u6020\u60C5\u5831\n    attendance: client_file_link_aggregate(where: {admin_reading_flag: {_eq: true}, file_type: {_eq: 3}}) {\n      aggregate {\n        count(columns: admin_reading_flag)\n      }\n    }\n    # \u52B4\u50CD\u901A\u77E5\u66F8\n    labornotice: client_file_link_aggregate(where: {admin_reading_flag: {_eq: true}, file_type: {_eq: 4}}) {\n      aggregate {\n        count(columns: admin_reading_flag)\n      }\n    }\n  }\n}\n"])));
var CLIENTS_LIST_COUNT = exports.CLIENTS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String) {\n  client_user_aggregate(order_by: {company_name: asc}, where: {_or: [{company_name: {_ilike: $search_word}}, {address1: {_ilike: $search_word}}, {phone_number: {_ilike: $search_word}}, {mail_address: {_ilike: $search_word}}]}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var GET_LOGINID = exports.GET_LOGINID = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery($login_id: String) {\n  client_user(where: {login_id: {_eq: $login_id}}) {\n    id\n    uid\n    company_name\n    ceo_name\n    zip_code\n    address1\n    address2\n    phone_number\n    fax_number\n    mobile_phone\n    mail_address\n    occupation\n    business_content\n    login_id\n    login_password\n    is_login\n    created_at\n    updated_at\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_COUNT = exports.CLIENTS_CONTRACT_LIST_COUNT = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean) {\n  client_file_link_aggregate(order_by: {admin_reading_flag: desc, date_of_acceptance: desc, release_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, _or: {title: {_ilike: $searchWord}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_COUNT_EX = exports.CLIENTS_CONTRACT_LIST_COUNT_EX = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_date_of_acceptance_null: Boolean) {\n  client_file_link_aggregate(order_by: {admin_reading_flag: desc, date_of_acceptance: desc, release_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance},  _or: {title: {_ilike: $searchWord}, date_of_acceptance: {_is_null: $is_date_of_acceptance_null}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_QUERY = exports.CLIENTS_CONTRACT_LIST_QUERY = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_date_of_acceptance_null: Boolean, $sorttype: Int, $page_top: Int, $page_size: Int) {\n  clients_contract_list_query(args:{\n    in_client_id: $client_id,\n    in_file_type: $file_type,\n    in_searchword: $searchWord,\n    in_is_acceptance: $is_acceptance,\n    in_is_date_of_acceptance_null: $is_date_of_acceptance_null,\n    in_sorttype: $sorttype,\n    in_page_offset: $page_top,\n    in_page_size: $page_size\n  }) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    is_release\n    release_date\n    admin_reading_flag \n  }\n  clients_contract_list_query_aggregate(args: {in_client_id: $client_id, in_file_type: $file_type, in_searchword: $searchWord, in_is_acceptance: $is_acceptance, in_is_date_of_acceptance_null: $is_date_of_acceptance_null, in_sorttype: $sorttype, in_page_offset: null, in_page_size: null}) {\n    aggregate {\n      count(columns: id)\n    }\n  }  \n}\n"])));
var CLIENTS_ATTENDANCE_LIST_COUNT = exports.CLIENTS_ATTENDANCE_LIST_COUNT = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean) {\n  client_file_link_aggregate(order_by: {admin_reading_flag: desc, create_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, _or: {title: {_ilike: $searchWord}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CLIENTS_ATTENDANCE_LIST_QUERY = exports.CLIENTS_ATTENDANCE_LIST_QUERY = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nquery($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $page_top: Int, $page_size: Int) {\n  client_file_link(order_by: {admin_reading_flag: desc, create_date: desc},\n    where: {\n      file_type: {_eq: $file_type},\n      client_id: {_eq: $client_id},\n      is_acceptance: {_eq: $is_acceptance},\n      _or: { title: {_ilike: $searchWord}\n      }}, offset: $page_top, limit: $page_size) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    is_release\n    release_date\n    admin_reading_flag\n  }\n}\n"])));
var CLIENTS_LABOR_NOTICE_LIST_COUNT = exports.CLIENTS_LABOR_NOTICE_LIST_COUNT = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean) {\n  client_file_link_aggregate(order_by: {admin_reading_flag: desc, create_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, _or: {title: {_ilike: $searchWord}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var GET_CLIENT_CONTRACT = exports.GET_CLIENT_CONTRACT = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: Int) {\n  client_file_link(\n    where: { id: {_eq: $id }}) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    is_release\n    release_date\n    admin_reading_flag\n    user:client_file_link {\n      company_name\n    }\n  }\n}\n"])));
var GET_CLIENT_FILE_READING = exports.GET_CLIENT_FILE_READING = (0, _graphqlTag.default)(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  client_file_link_aggregate(where: {admin_reading_flag: {_eq: true}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var GET_CLIENT_UPDATE_INFO_COUNT = exports.GET_CLIENT_UPDATE_INFO_COUNT = (0, _graphqlTag.default)(_templateObject13 || (_templateObject13 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  client_user_aggregate(where: {update_info: {_is_null: false}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var CHAT_CLIENTS_COUNT = exports.CHAT_CLIENTS_COUNT = (0, _graphqlTag.default)(_templateObject14 || (_templateObject14 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String) {\n  client_user_aggregate(where: {company_name: {_ilike: $searchWord}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var CHAT_CLIENTS_QUERY_EX = exports.CHAT_CLIENTS_QUERY_EX = (0, _graphqlTag.default)(_templateObject15 || (_templateObject15 = (0, _taggedTemplateLiteral2.default)(["\nquery ($searchWord: String, $page_top: Int, $page_size: Int) {\n  client_user(where: {company_name: {_ilike: $searchWord}}, offset: $page_top, limit: $page_size, order_by: {company_name: asc, updated_at: desc_nulls_last}) {\n    id\n    uid\n    company_name\n    created_at\n    updated_at\n    client_messages_aggregate(where: {from_user_type: {_eq: 2}}) { # \u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304B\u3089\u306E\u30E1\u30C3\u30BB\u30FC\u30B8\u306E\u307F\u30AB\u30A6\u30F3\u30C8\n      aggregate {\n        sum {\n          unread_count\n        }\n      }\n    }\n    client_messages(order_by: {create_date: desc}, limit: 1) {\n      message\n      from_user_type\n      unread_count\n      create_date\n      admin_user {\n        uid\n        name\n      }\n    }\n  }\n}\n"])));
var CHAT_CLIENT_ROOM_QUERY = exports.CHAT_CLIENT_ROOM_QUERY = (0, _graphqlTag.default)(_templateObject16 || (_templateObject16 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_uid: uuid) {\n  client_message(where: {client_uid: {_eq: $client_uid}}, order_by: {create_date: desc}) {\n    id\n    admin_uid\n    client_uid\n    message\n    reading_flag\n    create_date\n    client_user {\n      company_name\n    }\n    admin_user {\n      name\n    }\n  }\n}\n"])));
var CHAT_CLIENT_MESSAGE_QUERY = exports.CHAT_CLIENT_MESSAGE_QUERY = (0, _graphqlTag.default)(_templateObject17 || (_templateObject17 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_uid: uuid) {\n  client_message(where: {client_uid: {_eq: $client_uid}}, order_by: {create_date: asc}) {\n    id\n    admin_uid\n    client_uid\n    message\n    reading_flag\n    from_user_type\n    create_date\n    client_user {\n      company_name\n    }\n    admin_user {\n      name\n    }\n  }\n}\n"])));
var CHAT_MESSAGES_COUNT = exports.CHAT_MESSAGES_COUNT = (0, _graphqlTag.default)(_templateObject18 || (_templateObject18 = (0, _taggedTemplateLiteral2.default)(["\nquery ChatMessage($client_uid: uuid) {\n  client_message_aggregate(where: {client_uid: {_eq: $client_uid}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var GET_CLIENT_CHAT_COUNT = exports.GET_CLIENT_CHAT_COUNT = (0, _graphqlTag.default)(_templateObject19 || (_templateObject19 = (0, _taggedTemplateLiteral2.default)(["\nquery MyQuery {\n  client_message_aggregate(where: {from_user_type: {_eq: 2}}) {\n    aggregate {\n      sum{\n        unread_count\n      }\n    }\n  }\n}\n"])));
var DELETE_CLIENT_CONTRACT_WITH_DELETED_ID = exports.DELETE_CLIENT_CONTRACT_WITH_DELETED_ID = (0, _graphqlTag.default)(_templateObject20 || (_templateObject20 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteClientContractWithDeletedAt($id: Int!) {\n  update_client_file_link_by_pk(pk_columns: {id: $id}, _set: {deleted_at: \"now()\"}) {\n    id\n  }\n}\n"])));