var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container clearfix" }, [
    _c(
      "div",
      { staticClass: "user-list-block", attrs: { id: "user-list" } },
      [
        _c("el-autocomplete", {
          staticClass: "inline-input search-block",
          attrs: {
            "fetch-suggestions": _vm.querySearch,
            "prefix-icon": "el-icon-search",
            placeholder: "クライアント名",
          },
          on: { select: _vm.handleSelect },
          model: {
            value: _vm.searchWord,
            callback: function ($$v) {
              _vm.searchWord = $$v
            },
            expression: "searchWord",
          },
        }),
        _vm._v(" "),
        _c("el-collapse", {
          staticClass: "user-list",
          attrs: { accordion: "" },
          model: {
            value: _vm.activeCategory,
            callback: function ($$v) {
              _vm.activeCategory = $$v
            },
            expression: "activeCategory",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "chat" }, [
      _c("div", { staticClass: "chat-header clearfix" }, [
        _vm.client_list
          ? _c("h3", [_vm._v("クライアントへのチャット")])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _vm.client_list
            ? _c("div", { staticClass: "inline" }, [_vm._v("クライアント一覧")])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "32px" } },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goTopPage()
                },
              },
            },
            [_vm._v("先頭ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goPrePage()
                },
              },
            },
            [_vm._v("前ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goNextPage()
                },
              },
            },
            [_vm._v("次ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goLastPage()
                },
              },
            },
            [_vm._v("最終ページ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "divider" }),
      _vm._v(" "),
      _vm.client_list
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.chatClients, stripe: "", fit: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "クライアント名",
                      align: "center",
                      "min-width": "50",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === 0 ||
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === null
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#606266" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.company_name) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _c("p", { staticStyle: { color: "#f00" } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.company_name) +
                                        "\n            "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2429866899
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "最新メッセージ",
                      align: "center",
                      "min-width": "70",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.client_messages.length > 0
                                ? _c("div", [
                                    scope.row.client_messages_aggregate
                                      .aggregate.sum.unread_count === 0 ||
                                    scope.row.client_messages_aggregate
                                      .aggregate.sum.unread_count === null
                                      ? _c(
                                          "p",
                                          { staticStyle: { color: "#606266" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("truncate")(
                                                    scope.row.client_messages[0]
                                                      .message,
                                                    60,
                                                    "..."
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          { staticStyle: { color: "#f00" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("truncate")(
                                                    scope.row.client_messages[0]
                                                      .message,
                                                    60,
                                                    "..."
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3736309378
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新時間",
                      align: "center",
                      "min-width": "40",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.client_messages.length > 0
                                ? _c("div", [
                                    scope.row.client_messages_aggregate
                                      .aggregate.sum.unread_count === 0 ||
                                    scope.row.client_messages_aggregate
                                      .aggregate.sum.unread_count === null
                                      ? _c(
                                          "p",
                                          { staticStyle: { color: "#606266" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.displayDateTime(
                                                    scope.row.client_messages[0]
                                                      .create_date,
                                                    "YYYY/MM/DD"
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.displayDateTime(
                                                    scope.row.client_messages[0]
                                                      .create_date,
                                                    "HH:mm"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          { staticStyle: { color: "#f00" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.displayDateTime(
                                                    scope.row.client_messages[0]
                                                      .create_date,
                                                    "YYYY/MM/DD"
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.displayDateTime(
                                                    scope.row.client_messages[0]
                                                      .create_date,
                                                    "HH:mm"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      210881794
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新者",
                      align: "center",
                      "min-width": "50",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === 0 ||
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === null
                                ? _c("div", [
                                    scope.row.client_messages.length > 0
                                      ? _c("div", [
                                          scope.row.client_messages[0]
                                            .from_user_type === 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#606266",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row
                                                          .client_messages[0]
                                                          .admin_user.name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#606266",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.company_name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("div", [
                                    scope.row.client_messages.length > 0
                                      ? _c("div", [
                                          scope.row.client_messages[0]
                                            .from_user_type === 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#f00",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row
                                                          .client_messages[0]
                                                          .admin_user.name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#f00",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.company_name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      571143415
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    staticClass: "buttons",
                    attrs: { align: "center", "min-width": "45" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === 0 ||
                              scope.row.client_messages_aggregate.aggregate.sum
                                .unread_count === null
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToChat(scope.row.uid)
                                        },
                                      },
                                    },
                                    [_vm._v("チャット画面")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "background-color": "#f00",
                                        color: "#fff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToChat(scope.row.uid)
                                        },
                                      },
                                    },
                                    [_vm._v("チャット画面")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2395646551
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "32px" } },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goTopPage()
                },
              },
            },
            [_vm._v("先頭ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goPrePage()
                },
              },
            },
            [_vm._v("前ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goNextPage()
                },
              },
            },
            [_vm._v("次ページ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button-first",
              attrs: { type: "info", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.goLastPage()
                },
              },
            },
            [_vm._v("最終ページ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }