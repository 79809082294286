var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 5, type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-input", {
                model: {
                  value: _vm.searchWord,
                  callback: function ($$v) {
                    _vm.searchWord = $$v
                  },
                  expression: "searchWord",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.searchQa },
                },
                [_vm._v("検索")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }