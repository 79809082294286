"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertTimeToDate = convertTimeToDate;
exports.displayChatTime = displayChatTime;
exports.displayDate = displayDate;
exports.displayDateTime = displayDateTime;
exports.getTime = getTime;
exports.isAfterDay = isAfterDay;
exports.isBeforeDay = isBeforeDay;
exports.toJST = toJST;
exports.toUTC = toUTC;
exports.validTimeStr = validTimeStr;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
_momentTimezone.default.updateLocale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});
function displayDateTime(dateTime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!(0, _momentTimezone.default)(dateTime).isValid()) {
    return '';
  }
  if (!format) {
    format = 'YYYY/MM/DD HH:mm';
  }
  return (0, _momentTimezone.default)(dateTime).format(format);
}
function displayDate(dateTime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!(0, _momentTimezone.default)(dateTime).isValid()) {
    return '';
  }
  if (!format) {
    format = 'YYYY/MM/DD (ddd)';
  }
  return (0, _momentTimezone.default)(dateTime).format(format);
}

// Dateから時間を取得
function getTime(dateTime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!(0, _momentTimezone.default)(dateTime).isValid()) {
    return '';
  }
  if (!format) {
    format = 'HH:mm';
  }
  return (0, _momentTimezone.default)(dateTime).format(format);
}
function convertTimeToDate(timeStr) {
  // 入力がnullまたは空文字の場合
  if (!timeStr) {
    return null;
  }
  return (0, _momentTimezone.default)(timeStr, 'HH:mm').toDate();
}
function validTimeStr(input) {
  // 全角数字と全角コロンを半角に変換
  input = input.replace(/[０-９：]/g, function (char) {
    return String.fromCharCode(char.charCodeAt(0) - 0xFEE0);
  });
  // 数字と「:」以外の値が入った時はnull
  if (!/^[0-9:]+$/.test(input)) {
    return null;
  }

  // 「:」を除いて1～4文字でない時はnull
  var strippedInput = input.replace(/:/g, '');
  if (strippedInput.length < 1 || strippedInput.length > 4) {
    return null;
  }
  var hours, minutes;

  // 「:」がない時
  if (input.indexOf(':') === -1) {
    if (strippedInput.length === 4) {
      hours = parseInt(strippedInput.substring(0, 2), 10);
      minutes = parseInt(strippedInput.substring(2, 4), 10);
    } else if (strippedInput.length === 3) {
      hours = parseInt(strippedInput.substring(0, 1), 10);
      minutes = parseInt(strippedInput.substring(1, 3), 10);
    } else if (strippedInput.length === 2 || strippedInput.length === 1) {
      hours = parseInt(strippedInput, 10);
      minutes = 0;
    }
  } else {
    // 「:」がある時
    var parts = input.split(':');
    hours = parseInt(parts[0], 10);
    minutes = parts[1] === '' ? 0 : parseInt(parts[1], 10);
  }

  // 時間が0~23までの値かつ分が0~59の値でない時null
  if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return null;
  }

  // 時間と分を2桁にフォーマット
  var formattedHours = hours.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  return "".concat(formattedHours, ":").concat(formattedMinutes);
}

/**
 * @param {Date} dateTime
 * @returns {String}
 */
function displayChatTime(dateTime) {
  var today = (0, _momentTimezone.default)(new Date());
  var yesterday = (0, _momentTimezone.default)(today).subtract(1, 'days');
  var date = '';
  var day = '';
  var time = (0, _momentTimezone.default)(dateTime).format('HH:mm');
  //  今日
  if ((0, _momentTimezone.default)(today).isSame((0, _momentTimezone.default)(dateTime), 'days')) {
    date = '今日';
    //  昨日
  } else if ((0, _momentTimezone.default)(yesterday).isSame((0, _momentTimezone.default)(dateTime), 'days')) {
    date = '昨日';
  } else {
    date = (0, _momentTimezone.default)(dateTime).format('MM月DD日');
    if (today.year() !== (0, _momentTimezone.default)(dateTime).year()) {
      date = (0, _momentTimezone.default)(dateTime).format('YYYY年MM月DD日');
    }
    day = (0, _momentTimezone.default)(dateTime).format('(ddd)');
  }
  return "".concat(date, " ").concat(day, " ").concat(time);
}
function toUTC(timeStr) {
  if (!timeStr) {
    return null;
  }
  return _momentTimezone.default.tz(timeStr, 'Asia/Tokyo').utc().format();
}
function toJST(timeStr) {
  if (!timeStr) {
    return null;
  }
  return _momentTimezone.default.utc(timeStr).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm:ss');
}
function isAfterDay(date, other) {
  return (0, _momentTimezone.default)(date).isAfter(other, 'day');
}
function isBeforeDay(date, other) {
  return (0, _momentTimezone.default)(date).isBefore(other, 'day');
}