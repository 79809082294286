"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
var _default = exports.default = {
  data: function data() {
    return {
      max_size: 100
    };
  },
  methods: {
    checkImg: function checkImg(img) {
      console.log(JSON.stringify(img));
      var typeFile = ['png', 'jpg', 'jpeg'];
      var fileType = img['type'];
      var validImageTypes = ['image/jpg', 'image/png', 'image/jpeg'];
      if (!validImageTypes.includes(fileType)) {
        return false;
      }
      var type = img.name.split('.').pop();
      return typeFile.includes(type.toLowerCase());
    },
    checkVideo: function checkVideo(video) {
      var fileType = video['type'];
      var validImageTypes = ['video/mp4', 'video/quicktime'];
      if (!validImageTypes.includes(fileType)) {
        this.$toasted.error(this.$t('MESSAGE.ERR_COM_0019'), {
          position: 'top-right',
          theme: 'toasted-primary',
          duration: 5000
        });
        return false;
      }
      return true;
    },
    validateVideo: function validateVideo(file) {
      if (!file) return false;
      var size = file.size / 1024 / 1024;
      if (size > this.max_size) {
        this.$toasted.error(this.$t('MESSAGE.ERR_COM_0012'), {
          position: 'top-right',
          theme: 'toasted-primary',
          duration: 5000
        });
        return false;
      }
      return true;
    }
  }
};