"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CustomDialog',
  props: {
    title: {
      type: String,
      default: '確認'
    },
    message: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '30%'
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    yesText: {
      type: String,
      default: 'はい'
    },
    noText: {
      type: String,
      default: 'いいえ'
    },
    type: {
      type: String,
      default: 'confirm'
    },
    okText: {
      type: String,
      default: 'OK'
    }
  },
  watch: {
    visible: function visible(val) {
      if (!val) {
        this.$emit('close');
      }
    }
  },
  methods: {
    handleOK: function handleOK() {
      this.$emit('ok');
      this.visible = false;
    },
    handleYes: function handleYes() {
      this.$emit('yes');
      this.visible = false;
    },
    handleNo: function handleNo() {
      this.$emit('no');
      this.visible = false;
    },
    isConfirm: function isConfirm() {
      return this.type === 'confirm';
    }
  }
};