"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_SITEUSER = exports.CREATE_SITEUSERS = exports.ADD_SITEUSERS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3;
var CREATE_SITEUSERS = exports.CREATE_SITEUSERS = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation createSiteUser($objects: [site_user_insert_input!]!) {\n  insert_site_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: site_user_name_key,\n      update_columns: [\n        name\n        email\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      uid\n      name\n      avatar\n      email\n      has_chat_room\n      permission\n      room_comment\n    }\n  }\n}\n"])));
var ADD_SITEUSERS = exports.ADD_SITEUSERS = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation addSiteUser($name: String, $email: String) {\n  insert_site_user(objects: {\n    name: $name,\n    email: $email\n  }) {\n    affected_rows\n    returning {\n      id\n      uid\n      name\n      email\n    }\n  }\n}\n"])));
var UPDATE_SITEUSER = exports.UPDATE_SITEUSER = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateSiteUser(\n  $uid: uuid,\n  $objects: site_user_set_input\n) {\n  update_site_user(\n    where: {uid: {_eq: $uid}},\n    _set: $objects\n  ) {\n    affected_rows,\n    returning {\n      id\n      uid\n      name\n      email\n    }  \n  }\n}\n"])));