var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "QaForm",
          staticClass: "textColor",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.sections,
                  expression: "showForm.sections",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "textColor",
                      attrs: {
                        prop: "sections",
                        label: "セクション",
                        rules: _vm.rules.sections,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "value-key": "id",
                            placeholder: "セクションを選択してください",
                          },
                          model: {
                            value: _vm.form.sections,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sections", $$v)
                            },
                            expression: "form.sections",
                          },
                        },
                        _vm._l(_vm.sections, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.title,
                  expression: "showForm.title",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "textColor",
                      attrs: {
                        prop: "title",
                        label: "質問",
                        rules: _vm.rules.title,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 3 },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.descriptions,
                  expression: "showForm.descriptions",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "textColor",
                      attrs: {
                        prop: "descriptions",
                        label: "回答",
                        rules: _vm.rules.descriptions,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 3 },
                        model: {
                          value: _vm.form.descriptions,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "descriptions", $$v)
                          },
                          expression: "form.descriptions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.pdf_file,
                  expression: "showForm.pdf_file",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 15 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "textColor",
                      attrs: { prop: "fileList", label: "添付ファイル（PDF）" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload",
                          attrs: {
                            drag: "",
                            action: "",
                            "show-file-list": false,
                            limit: 3,
                            "on-change": _vm.handleAdd,
                            "on-remove": _vm.handleRemove,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                            "list-type": "pdf",
                            multiple: "",
                            "auto-upload": false,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("ファイルをドロップする"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.orgImgData.length > 0 && _vm.fileList.length === 0
                        ? _c("div", [
                            _c("img", {
                              staticClass: "preview",
                              attrs: { src: _vm.orgImgData },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.info.fileArray, function (item, index) {
            return _c(
              "el-row",
              { key: index },
              [
                _c("el-col", { attrs: { md: 50 } }, [
                  item.id !== ""
                    ? _c("div", { staticStyle: { width: "360px" } }, [
                        _c(
                          "a",
                          {
                            staticStyle: { "text-decoration": "underline" },
                            attrs: { href: item.url, target: "_blank" },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "button",
                          {
                            staticStyle: { float: "right" },
                            on: {
                              click: function ($event) {
                                return _vm.fileDelete(item.id)
                              },
                            },
                          },
                          [_vm._v("削除")]
                        ),
                      ])
                    : _c("div", { staticStyle: { width: "360px" } }, [
                        _vm._v(_vm._s(item.name)),
                        _c(
                          "button",
                          {
                            staticStyle: { float: "right" },
                            on: {
                              click: function ($event) {
                                return _vm.fileDelete(item.id)
                              },
                            },
                          },
                          [_vm._v("削除")]
                        ),
                      ]),
                ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-block" },
            [
              _c(
                "el-form-item",
                [
                  _vm.info.is_edit == true
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submit(false)
                            },
                          },
                        },
                        [_vm._v("削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submit(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.submitBtnName))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.centerDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("Q&Aの" + _vm._s(_vm.submitBtnName) + "を行いますか？"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addQa } },
                    [_vm._v("はい")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.deleteDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deleteDialogVisible = $event
                },
              },
            },
            [
              _c("span", [_vm._v("Q&Aの削除を行いますか？")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.deleteDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.deleteQa } },
                    [_vm._v("はい")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.info.waitDialogVisible,
                width: "30%",
                "close-on-click-modal": false,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.info, "waitDialogVisible", $event)
                },
              },
            },
            [_c("span", [_vm._v("登録中！")])]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }