var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container clearfix" }, [
    _c("div", { staticClass: "chat" }, [
      _c("div", { staticClass: "chat-history", attrs: { id: "vscr" } }, [
        _c("ul", [
          _c("div", [
            _c(
              "div",
              _vm._l(_vm.chatMsgs, function (msg, msgIndex) {
                return _c(
                  "li",
                  { key: msgIndex, staticClass: "clearfix" },
                  [
                    msg.from_user_type === 2
                      ? [
                          _c(
                            "div",
                            { staticClass: "message-data align-right" },
                            [
                              _c(
                                "span",
                                { staticClass: "message-data-time textColor" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.displayMsgTime(msg.create_date))
                                  ),
                                ]
                              ),
                              _vm._v("    \n                  "),
                              _c(
                                "span",
                                { staticClass: "message-data-name textColor" },
                                [_vm._v(_vm._s(msg.client_user.company_name))]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "fa fa-circle company" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "message company-message float-right ",
                            },
                            [
                              _c("div", { staticClass: "textColor" }, [
                                _vm._v(_vm._s(msg.message)),
                              ]),
                            ]
                          ),
                        ]
                      : [
                          _c("div", { staticClass: "message-data" }, [
                            _c(
                              "span",
                              { staticClass: "message-data-name textColor" },
                              [
                                _c("i", { staticClass: "fa fa-circle online" }),
                                _vm._v(" " + _vm._s(msg.admin_user.name)),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "message-data-time textColor" },
                              [
                                _vm._v(
                                  _vm._s(_vm.displayMsgTime(msg.create_date))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "message user-message" }, [
                            _c("div", { staticClass: "textColor" }, [
                              _vm._v(_vm._s(msg.message)),
                            ]),
                          ]),
                        ],
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "divider" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chat-message clearfix chat-form" },
        [
          _c(
            "el-form",
            [
              _c(
                "div",
                {
                  staticClass: "float-left textColor",
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.roomData.admin) +
                      _vm._s("⇄" + _vm.roomData.client)
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "message-to-send",
                attrs: {
                  type: "textarea",
                  rows: 3,
                  size: "large",
                  placeholder: "メッセージを入力してください",
                },
                model: {
                  value: _vm.inputMessage,
                  callback: function ($$v) {
                    _vm.inputMessage = $$v
                  },
                  expression: "inputMessage",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button send-button",
                  attrs: { type: "primary", disabled: _vm.sendingMassage },
                  on: { click: _vm.onSendMessage },
                },
                [_vm._v("送信")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "send-button", on: { click: _vm.onBack } },
                [_vm._v("戻る")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("i", { staticClass: "fa fa-circle online" }),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-circle online",
        staticStyle: { color: "#AED2A6" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }