"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadingType = void 0;
var ReadingType = exports.ReadingType = {
  ReadingType: {
    UnRead: {
      val: true,
      label: '未読'
    },
    Read: {
      val: false,
      label: '既読'
    }
  }
};