var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("h3", { staticClass: "header-title" }, [_vm._v("Q&A一覧")]),
        _vm._v(" "),
        _c("QaFilter"),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.CreateQA()
                  },
                },
              },
              [_vm._v("Q&A作成")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.AddSection()
                  },
                },
              },
              [_vm._v("セクション追加")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.toggleEditMode()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.isEditMode ? "閲覧" : "編集"))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isEditMode
          ? _c("div", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "table",
                { staticStyle: { width: "100%", "table-layout": "fixed" } },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { element: "tbody", animation: 200 },
                      model: {
                        value: _vm.qas,
                        callback: function ($$v) {
                          _vm.qas = $$v
                        },
                        expression: "qas",
                      },
                    },
                    _vm._l(_vm.qas, function (item, index) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.section.name))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("truncate")(item.title, 60, "..."))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "text-align": "righ" } },
                          [
                            item.published_at == null
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "info", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.published(0, item.id)
                                        item.published_at = 1
                                      },
                                    },
                                  },
                                  [_vm._v("未公開")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "info", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.published(1, item.id)
                                        item.published_at = null
                                      },
                                    },
                                  },
                                  [_vm._v("公開")]
                                ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToEdit(item.id)
                                  },
                                },
                              },
                              [_vm._v("編集")]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.qas, stripe: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    width: 75,
                    label: "No",
                    align: "left",
                    "max-width": "75",
                    "min-width": "75",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.$index + 1) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "section.name",
                    label: "セクション",
                    align: "left",
                    "min-width": "75",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "質問", align: "left", "min-width": "75" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("truncate")(scope.row.title, 60, "...")
                              ) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { width: 95, align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.published_at == null
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "info", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.published(0, scope.row.id)
                                      scope.row.published_at = 1
                                    },
                                  },
                                },
                                [_vm._v("未公開")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "info", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.published(1, scope.row.id)
                                      scope.row.published_at = null
                                    },
                                  },
                                },
                                [_vm._v("公開")]
                              ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { width: 80, align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToEdit(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("編集")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "32px" } },
      [
        _c("label", [
          _vm._v(_vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goTopPage()
              },
            },
          },
          [_vm._v("先頭ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goPrePage()
              },
            },
          },
          [_vm._v("前ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goNextPage()
              },
            },
          },
          [_vm._v("次ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goLastPage()
              },
            },
          },
          [_vm._v("最終ページ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "table-header",
        staticStyle: { width: "100%", "text-align": "start" },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-size": "14px", "font-weight": "normal" } },
          [
            _vm._v(
              "・枠をクリックしたまま移動することで、並び替えを行うことができます。"
            ),
            _c("br"),
            _vm._v("・並び順はアプリにそのまま反映されます。"),
            _c("br"),
            _vm._v(
              "・セクションの並び替えは「セクション追加」から行ってください。"
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "75px" } }, [_vm._v("No")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "auto", "min-width": "75px" } }, [
          _vm._v("セクション"),
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "auto", "min-width": "75px" } }, [
          _vm._v("質問"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { width: "190px" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }