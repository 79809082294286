"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SITEUSER_QUERY = exports.SITEUSER_LIST_BY_HAS_CHAT_ROOM_QUERY = exports.SITEUSERS_UNREAD_COUNT = exports.SITEUSERS_LIST_QUERY = exports.SITEUSERS_LIST_COUNT = exports.GET_SITEUSER_NAME = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var SITEUSER_QUERY = exports.SITEUSER_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($id: uuid) {\n  site_user(where: {uid: {_eq: $id}}) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    room_comment\n  }\n}\n"])));
var SITEUSERS_LIST_QUERY = exports.SITEUSERS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String, $page_top: Int, $page_size: Int) {\n  site_user(order_by: {id: asc}, where: {_or: [{name: {_ilike: $search_word}}, {email: {_ilike: $search_word}}]}, offset: $page_top, limit: $page_size) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n#    count: chat_rooms_aggregate {\n#      aggregate {\n#        sum {\n#          unread_count\n#        }\n#      }\n#    }\n  }\n}\n"])));
var SITEUSERS_UNREAD_COUNT = exports.SITEUSERS_UNREAD_COUNT = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery ($uid: uuid) {\n  chat_room_aggregate(where: {site_user_id: {_eq: $uid}}) {\n    aggregate {\n      sum {\n        unread_count\n      }\n    }\n  }\n}\n"])));
var SITEUSERS_LIST_COUNT = exports.SITEUSERS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String) {\n  site_user_aggregate(order_by: {id: asc}, where: {_or: [{name: {_ilike: $search_word}}, {email: {_ilike: $search_word}}]}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var SITEUSER_LIST_BY_HAS_CHAT_ROOM_QUERY = exports.SITEUSER_LIST_BY_HAS_CHAT_ROOM_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  site_user(\n    where: {has_chat_room: {_eq: true}}\n    order_by: {id:asc}\n  ) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    room_comment\n  }\n}\n"])));
var GET_SITEUSER_NAME = exports.GET_SITEUSER_NAME = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery($name: String) {\n  site_user(\n    where: {\n      name: {_eq: $name}\n    }\n    order_by: {id:asc}\n  ) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    room_comment\n  }\n}\n"])));