var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c("div", { staticClass: "app-container" }, [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                staticClass: "midButton",
                on: { click: _vm.backToLaborNoticeIndex },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" 労働通知書"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "company_name",
                    label: "会社名",
                    align: "left",
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.company_name) + "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", label: "タイトル", align: "left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { color: "gray", background: "#DCF2FF" },
                data: _vm.form,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "公開日時", align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.displayDateTime(scope.row.release_date)
                            ) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "header-title" }, [_vm._v("労働通知書内容")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "top", "label-width": "250px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-form-item", [
                        _c("iframe", {
                          attrs: {
                            src: _vm.form[0].file_url,
                            width: "100%",
                            height: "800",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "midButton",
              on: { click: _vm.backToLaborNoticeIndex },
            },
            [_vm._v("戻る")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }