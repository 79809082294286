var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "header-title" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.backToJobs()
                    },
                  },
                },
                [_vm._v("戻る")]
              ),
              _vm._v(" 求人作成\n      "),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("JobForm"),
        _vm._v(" "),
        _c(
          "el-row",
          {
            staticStyle: { "padding-left": "12px", "padding-top": "40px" },
            attrs: { gutter: 20 },
          },
          [
            _c(
              "el-col",
              { attrs: { span: 10 } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { disabled: _vm.loading, type: "primary" },
                    on: { click: _vm.submitJob },
                  },
                  [_vm._v("登録")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { height: "40px" } }),
        _vm._v(" "),
        _c("el-dialog", {
          attrs: {
            title: "登録中!",
            visible: _vm.loading,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.loading = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }