"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPSERT_CHAT_USER_AND_ROOM = exports.UPDATE_CHAT_ROOM_NAME = exports.INSERT_CHAT_ROOM_EXT = exports.INSERT_CHAT_ROOM = exports.CLEAR_MESSAGE_COUNT = exports.ADD_CHAT_USER = exports.ADD_CHAT_MESSAGE = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var ADD_CHAT_USER = exports.ADD_CHAT_USER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatUser(\n  $objects: [chat_user_insert_input!]!\n) {\n  insert_chat_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_user_uid_key,\n      update_columns: [\n        name,\n        email,\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      avatar\n      is_siteuser\n      is_category\n      name\n      email\n      uid\n    }\n  }\n}\n"])));
var UPSERT_CHAT_USER_AND_ROOM = exports.UPSERT_CHAT_USER_AND_ROOM = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation addOrUpdateChatUser(\n  $objects: [chat_user_insert_input!]!,\n  $adminId: uuid,\n  $chatRoomObj: chat_room_set_input\n) {\n  insert_chat_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_user_uid_key,\n      update_columns: [\n        name,\n        email,\n        avatar,\n        is_siteuser\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      avatar\n      is_siteuser\n      name\n      email\n      uid\n    }\n  }\n  update_chat_room (\n    where: {site_user_id: {_eq: $adminId}}\n    _set: $chatRoomObj\n  ) {\n    affected_rows\n  }\n}\n"])));
var INSERT_CHAT_ROOM = exports.INSERT_CHAT_ROOM = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatRoom($objects: [chat_room_insert_input!]!) {\n  insert_chat_room(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_room_owner_id_site_user_id_chat_room_sub_category_id_key,\n      update_columns: [\n        chat_room_name,\n        thumbnail\n        room_comment\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      owner_id\n      site_user_id\n      chat_room_category_id\n      chat_room_sub_category_id\n      chat_room_name\n      thumbnail\n      room_comment\n      room_members {\n        user_id\n        chat_room_id\n      }\n    }\n  }\n}\n"])));
var INSERT_CHAT_ROOM_EXT = exports.INSERT_CHAT_ROOM_EXT = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatRoom($objects: [chat_room_insert_input!]!) {\n  insert_chat_room(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_room_chat_room_sub_category_id_chat_room_name_owner_id_sit, # 20200623 chat_room_chat_room_name_owner_id_site_user_id_chat_room_sub_ca,\n      update_columns: [\n        chat_room_name,\n        thumbnail\n        room_comment\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      owner_id\n      site_user_id\n      chat_room_category_id\n      chat_room_sub_category_id\n      chat_room_name\n      thumbnail\n      room_comment\n      room_members {\n        user_id\n        chat_room_id\n      }\n    }\n  }\n}\n"])));
var ADD_CHAT_MESSAGE = exports.ADD_CHAT_MESSAGE = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatMessage(\n    $message: String,\n    $attachFileType: Int,\n    $fileName: String,\n    $fileUrl: String,\n    $siteUserId: uuid,\n    $chatRoomId: Int, \n    $from_admin_uid: uuid\n  ) {\n  insert_messages(objects: {\n    messeger_content: $message,\n    attach_file_type: $attachFileType,\n    file_name: $fileName,\n    file_url: $fileUrl,\n    chat_room_id: $chatRoomId,\n    from_user_id: $siteUserId,\n    from_admin_uid: $from_admin_uid\n  }) {\n    returning {\n      messeger_content\n      id\n      from_user_id\n      from_admin_uid\n      file_url\n      file_name\n      chat_room_id\n      created_at\n      attach_file_type\n      chat_user {\n        name\n      }\n    }\n  }\n}\n"])));
var UPDATE_CHAT_ROOM_NAME = exports.UPDATE_CHAT_ROOM_NAME = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateChatRoomName($org_name: String, $to_name: String) {\n  update_chat_room(where: \n    {\n      chat_room_name: {_eq: $org_name},\n      deleted_at: {_is_null: true}\n    },\n    _set: {\n      chat_room_name: $to_name\n    }) {\n    affected_rows\n    returning {\n      id\n      owner_id\n      site_user_id\n      chat_room_name\n      chat_room_sub_category_id\n      chat_room_category_id\n      thumbnail\n      memo\n      room_comment\n      deleted_at\n      created_at\n      updated_at\n    }\n  }\n  update_chat_user(where: \n    {\n      name: {_eq: $org_name},\n      is_siteuser: {_eq: true},\n      deleted_at: {_is_null: true}\n    },\n    _set: {\n      name: $to_name\n    }) {\n    affected_rows\n    returning {\n      id\n      uid\n      name\n      avatar\n      email\n      is_siteuser\n      updated_at\n      created_at\n      deleted_at\n    }\n  }\n}\n"])));
var CLEAR_MESSAGE_COUNT = exports.CLEAR_MESSAGE_COUNT = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nmutation clearMessageCount($roomId: Int, $userId: uuid) {\n  update_room_member(where: \n    {\n      chat_room_id: {_eq: $roomId},\n      user_id: {_eq: $userId}\n    },\n    _set: {\n      unread_count: 0\n    }) {\n    affected_rows\n  }\n  update_chat_room(where: \n    {\n      id: {_eq: $roomId}\n    },\n    _set: {\n      unread_count: 0\n    }) {\n    affected_rows\n  }\n\n}\n"])));