"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPSEART_ADMIN_ENTRY_READ = exports.UPDATE_ADMIN = exports.CREATE_ADMIN = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3;
var CREATE_ADMIN = exports.CREATE_ADMIN = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation createAdmin(\n  $name: String,\n  $email: String,\n  $permission: Int,\n  $avatar: String,\n  $password: String,\n  $hasChatRoom: Boolean\n  $roomComment: String,\n  $is_active: Boolean\n) {\n  insert_admin(objects: {\n    name: $name,\n    email: $email,\n    avatar: $avatar,\n    permission: $permission,\n    password: $password,\n    has_chat_room: $hasChatRoom\n    room_comment: $roomComment\n    is_active: $is_active\n  }) {\n    affected_rows\n    returning {\n      uid\n      name\n      email\n      avatar\n      permission\n      has_chat_room\n      room_comment\n      is_active\n    }\n  }\n}\n"])));
var UPDATE_ADMIN = exports.UPDATE_ADMIN = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateAdmin(\n  $id: uuid,\n  $objects: admin_set_input\n) {\n  update_admin(\n    where: {uid: {_eq: $id}},\n    _set: $objects\n  ) {\n    affected_rows,\n    returning {\n      uid\n      name\n      email\n      avatar\n      permission\n      has_chat_room\n      room_comment\n    }  \n  }\n}\n"])));
var UPSEART_ADMIN_ENTRY_READ = exports.UPSEART_ADMIN_ENTRY_READ = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation upseartAdminEntryRead($job_id: Int, $admin_uid: uuid) {\n  insert_admin_entry_read(objects: {job_id: $job_id, admin_uid: $admin_uid}, on_conflict: {constraint: admin_entry_read_pkey, update_columns: updated_at}) {\n    affected_rows\n  }\n}\n"])));