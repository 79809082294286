"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _apolloClient = require("@/apollo/apolloClient");
var _siteuserQuery = require("@/apollo/queries/siteuser-query");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { validEmail } from '@/utils/validate'
// import { apolloClient } from '@/apollo/apolloClient'
// import { GET_LOGINID } from '@/apollo/queries/siteuser-query'
var _default2 = exports.default = {
  props: {
    disableForm: {
      type: Object,
      default: function _default() {
        return {
          name: true,
          email: false
        };
      }
    },
    showForm: {
      type: Object,
      default: function _default() {
        return {
          name: true,
          email: true
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          org_name: ''
        };
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '新規作成';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    /*
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error('メールアドレスを正しく入力してください'))
      } else {
        callback()
      }
    }
    */
    var validateSiteName = function validateSiteName(rule, value, callback) {
      if (value === '' || value === null || value === undefined) {
        callback(new Error('サイト名を入力してください'));
        return;
      }
      if (_this.form.org_name === value) {
        callback();
        return;
      }
      _apolloClient.apolloClient.query({
        query: _siteuserQuery.GET_SITEUSER_NAME,
        variables: {
          name: value
        }
      }).then(function (result) {
        if (result.data.site_user.length > 0) {
          callback(new Error('すでに登録されているサイト名です'));
        } else {
          callback();
        }
      }).catch(function (error) {
        console.log(error);
        callback(new Error('データベースのアクセスに失敗しました'));
      });
    };
    return {
      centerDialogVisible: false,
      passwordType: 'password',
      capsTooltip: false,
      rules: {
        name: [{
          required: true,
          trigger: 'blur',
          validator: validateSiteName
        }]
        // email: [{ required: true, trigger: 'blur', validator: validateEmail }]
      }
    };
  },
  computed: {},
  methods: {
    // パスワード確認
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    activePwd: function activePwd() {
      this.form.pass_check = !this.form.pass_check;
      if (this.form.pass_check === true) {
        this.disableForm.login_password = false;
        this.disableForm.confirmed_password = false;
        this.form.login_password = '';
      } else {
        this.disableForm.login_password = true;
        this.disableForm.confirmed_password = true;
      }
    },
    submit: function submit() {
      var _this3 = this;
      console.log('submit');
      this.$refs.siteUserForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this3.centerDialogVisible = true;
      });
    },
    isHanEisu: function isHanEisu(str) {
      str = str === null ? '' : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    siteUserProcess: function siteUserProcess() {
      var _this4 = this;
      this.centerDialogVisible = false;
      this.$refs.siteUserForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        return _this4.$emit('postFormSubmit', _this4.form);
      });
    }
  }
};