"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NEWS_WITH_SURVEY_AGGREGATE_QUERY = exports.NEWS_WITH_DISTRIBUTIONS_QUERY = exports.NEWS_QUERY = exports.NEWSS_LIST_QUERY = exports.NEWSS_LIST_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var NEWSS_LIST_COUNT = exports.NEWSS_LIST_COUNT = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery ($title: String, $description: String, $distributionType: Int) {\n  news_aggregate(where: {_and: {distribution_type: {_eq: $distributionType}, _or: [{title: {_ilike: $title}}, {description: {_ilike: $description}}]}}, order_by: {created_at: desc}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var NEWSS_LIST_QUERY = exports.NEWSS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery($title: String, $description: String, $distributionType: Int, $page_top: Int, $page_size: Int) {\n  news(\n    where: {\n      _and: {\n        distribution_type: {_eq: $distributionType}\n        _or: [\n          {title: {_ilike: $title}}, \n          {description: {_ilike: $description}}\n        ]\n      }\n    },\n    order_by: {created_at: desc}, offset: $page_top, limit: $page_size\n  ) {\n    id\n    title\n    description\n    published_at\n    push_notification\n    distribution_type\n    delivery_type\n    has_news_survey\n    expiration_at\n    updated_at\n    is_published\n  }\n}\n"])));
var NEWS_QUERY = exports.NEWS_QUERY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery ($id: Int){\n  news(where: {id: {_eq: $id}}) {\n    id\n    title\n    description\n    published_at\n    push_notification\n    delivery_type\n    distribution_type\n    image_url\n    has_news_survey\n    expiration_at\n    is_publish_reserved\n    is_published\n  }\n}\n"])));
var NEWS_WITH_DISTRIBUTIONS_QUERY = exports.NEWS_WITH_DISTRIBUTIONS_QUERY = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ($id: Int){\n  news(where: {id: {_eq: $id}}) {\n    id\n    title\n    description\n    published_at\n    push_notification\n    delivery_type\n    distribution_type\n    image_url\n    has_news_survey\n    expiration_at\n    is_publish_reserved\n    is_published\n  }\n  distributions: news_distributions(where: {news_id: {_eq: $id}}) {\n    user {\n      id\n      name\n      staff_type\n    }\n  }\n}\n"])));
var NEWS_WITH_SURVEY_AGGREGATE_QUERY = exports.NEWS_WITH_SURVEY_AGGREGATE_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery ($id: Int){\n  news(where: {id: {_eq: $id}}) {\n    id\n    title\n    description\n    published_at\n    push_notification\n    delivery_type\n    distribution_type\n    image_url\n    has_news_survey\n    expiration_at\n    is_publish_reserved\n    is_published\n    distribution: news_distributions_aggregate {\n      aggregate {\n        count\n      }\n    }\n  }\n  answer_result: news_survey_answer_count(where: {news_id: {_eq: $id}}) {\n    no_answer\n    yes_answer\n  }\n  answers: news_survey_answer(\n    where: {\n      news_id: {_eq: $id},\n      answer: {_eq: true}\n    }\n  ) {\n    answer\n    user {\n      name\n      id\n    }\n  }\n}\n"])));