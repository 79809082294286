"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_CHAT_ROOM_ID = exports.GET_CHAT_COUNT = exports.CHAT_USER_LIST = exports.CHAT_USERS_BY_IS_SITEUSER_QUERY = exports.CHAT_ROOM_USER_LIST_QUERY_EX = exports.CHAT_ROOM_USER_LIST_COUNT = exports.CHAT_ROOM_QUERY = exports.CHAT_ROOM_LIST_QUERY = exports.CHAT_ROOM_BY_SITEUSER_QUERY = exports.CHAT_MESSAGES_QUERY = exports.CHAT_MESSAGES_COUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
var CHAT_ROOM_QUERY = exports.CHAT_ROOM_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($roomId: Int) {\n  chat_room(where: {id: {_eq: $roomId}}) {\n    id\n    chat_room_name\n    site_user_id\n    unread_count\n    site_user {\n      name\n      avatar\n    }\n    owner {\n      name\n      avatar\n    }\n    room_category {\n      category_name\n      category_name_site\n    }\n  }\n}\n"])));
var CHAT_ROOM_BY_SITEUSER_QUERY = exports.CHAT_ROOM_BY_SITEUSER_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery($subCategoryId: Int, $ownerId: uuid, $siteUserId: uuid) {\n  chat_room(\n    where: {\n      chat_room_sub_category_id: {_eq: $subCategoryId},\n      owner_id: {_eq: $ownerId},\n      site_user_id: {_eq: $siteUserId}\n  }) {\n    id\n  }\n}\n"])));
var CHAT_MESSAGES_QUERY = exports.CHAT_MESSAGES_QUERY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery ChatMessage($roomId: Int) {\n  messages(\n    where: {\n      chat_room_id: {_eq: $roomId},\n      deleted_at: {_is_null: true}\n    },\n    order_by: {created_at: asc}\n  ) {\n    messeger_content\n    id\n    from_user_id\n    file_url\n    file_name\n    chat_room_id\n    created_at\n    attach_file_type\n    chat_user {\n      name\n      is_category\n      is_siteuser\n    }\n  }\n}\n"])));
var CHAT_MESSAGES_COUNT = exports.CHAT_MESSAGES_COUNT = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ChatMessage($roomId: Int) {\n  messages_aggregate(\n    where: {\n      chat_room_id: {_eq: $roomId},\n      deleted_at: {_is_null: true}\n    }\n  ) {\n    aggregate{\n      count\n    }\n  }\n}\n"])));
var CHAT_ROOM_LIST_QUERY = exports.CHAT_ROOM_LIST_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  chat_room_category(\n    order_by: {id: asc, parent_id: asc},\n    where: {parent_id: {_is_null: true}}\n  ) {\n    category_name\n    category_name_site\n    id\n    rooms(distinct_on: chat_room_name, where: {chat_room_sub_category_id: {_neq: 4}}) {\n    # rooms(distinct_on: chat_room_name) {\n        id\n      chat_room_category_id\n      chat_room_sub_category_id\n      chat_room_name\n      site_user_id\n      thumbnail\n      unread_count\n#      room_members_info_aggregate(where: {user_type: {_eq: 1}}) {\n#        aggregate {\n#          sum {\n#            unread_count\n#          }\n#        }\n#      }\n    }\n  }\n}\n"])));
var CHAT_ROOM_USER_LIST_QUERY_EX = exports.CHAT_ROOM_USER_LIST_QUERY_EX = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery ($categoryId: Int, $subCategoryId: Int, $siteUserId: uuid, $searchWord: String, $page_top: Int, $page_size: Int) {\n  chat_room(where: {chat_room_category_id: {_eq: $categoryId}, chat_room_sub_category_id: {_eq: $subCategoryId}, site_user_id: {_eq: $siteUserId}, owner: {name: {_ilike: $searchWord}}}, offset: $page_top, limit: $page_size, order_by: {unread_count: desc, updated_at: desc_nulls_last, owner: {name: asc}}) {\n    id\n    chat_room_name\n    unread_count\n    messages(limit: 1, order_by: {created_at: desc}) {\n      chat_room_id\n      from_user_id\n      messeger_content\n      created_at\n      updated_at\n      attach_file_type\n      admin_user {\n        name\n      }\n      chat_user {\n        name\n      }\n      chat_room {\n        id\n      }\n    }\n    room_category {\n      category_name\n      category_name_site\n    }\n    owner {\n      name\n      user_id\n    }\n  }\n}\n"])));
var CHAT_ROOM_USER_LIST_COUNT = exports.CHAT_ROOM_USER_LIST_COUNT = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nquery ($categoryId: Int, $subCategoryId: Int, $siteUserId: uuid, $searchWord: String) {\n  chat_room_aggregate(where: {chat_room_category_id: {_eq: $categoryId}, chat_room_sub_category_id: {_eq: $subCategoryId}, site_user_id: {_eq: $siteUserId}, owner: {name: {_ilike: $searchWord}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CHAT_USERS_BY_IS_SITEUSER_QUERY = exports.CHAT_USERS_BY_IS_SITEUSER_QUERY = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nquery($isSiteUser: Boolean) {\n  chat_user(where: {is_siteuser: {_eq: $isSiteUser}}) {\n    avatar\n    email\n    name\n    uid\n  }\n}\n"])));
var CHAT_USER_LIST = exports.CHAT_USER_LIST = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nquery($site_user_id: uuid, $word: String) {\n  chat_room(where: {\n    site_user_id: {_eq: $site_user_id},\n    _or: { owner: {name: {_ilike: $word}} }\n  },\n    order_by: {owner: {name: desc}}) {\n    id\n    chat_room_name\n    owner {\n      uid\n      name\n    }\n  }\n}\n"])));
var GET_CHAT_ROOM_ID = exports.GET_CHAT_ROOM_ID = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nquery($owner_id: uuid){\n  chat_room(\n    where: {\n      chat_room_category_id: {_eq: 1},\n      chat_room_sub_category_id: {_eq: 3},\n      owner_id: {_eq: $owner_id}\n    }) {\n    id\n  }\n}\n"])));
var GET_CHAT_COUNT = exports.GET_CHAT_COUNT = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nquery{\n  room_member_aggregate(\n    where: {\n      user_type: {_eq: 1}\n    }) {\n      aggregate {\n        sum {\n          unread_count\n        }\n      }\n  }\n}\n"])));