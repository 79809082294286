var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" スタッフ選択"),
          ],
          1
        ),
        _vm._v(" "),
        _c("StaffFilter"),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goTopPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
            _vm._v(" "),
            _vm.add_news_mode === true
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    staticStyle: { color: "#f00" },
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goStaffList()
                      },
                    },
                  },
                  [_vm._v("スタッフ選択確認")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.add_news_mode === true
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    staticStyle: { color: "#f00" },
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.confim()
                      },
                    },
                  },
                  [_vm._v("確定")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.user, stripe: "", fit: "" },
              },
              [
                _vm.add_news_mode === true
                  ? _c("el-table-column", {
                      attrs: {
                        label: "選択",
                        align: "center",
                        "min-width": "30",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkedId,
                                      expression: "checkedId",
                                    },
                                  ],
                                  attrs: {
                                    id: scope.row.uid,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    value: scope.row.id,
                                    checked: Array.isArray(_vm.checkedId)
                                      ? _vm._i(_vm.checkedId, scope.row.id) > -1
                                      : _vm.checkedId,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.checkedId,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = scope.row.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checkedId = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checkedId = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checkedId = $$c
                                      }
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2132354426
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "id", label: "スタッフ番号", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "staff_type",
                    label: "スタッフ区分",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "氏名", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "gender",
                    label: "性別",
                    align: "center",
                    "min-width": "35",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "get_age",
                    label: "年齢",
                    align: "center",
                    "min-width": "35",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "admin_name",
                    label: "担当者名",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center", "min-width": "75" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail(scope.row.uid)
                                },
                              },
                            },
                            [_vm._v("詳細")]
                          ),
                          _vm._v(" "),
                          _vm.add_news_mode === false
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "info", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToAddStaff(
                                        scope.row.id,
                                        scope.row.uid
                                      )
                                    },
                                  },
                                },
                                [_vm._v("スタッフを追加")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goTopPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
            _vm._v(" "),
            _vm.add_news_mode === true
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    staticStyle: { color: "#f00" },
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goStaffList()
                      },
                    },
                  },
                  [_vm._v("スタッフ選択確認")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.add_news_mode === true
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-first",
                    staticStyle: { color: "#f00" },
                    attrs: { type: "info", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.confim()
                      },
                    },
                  },
                  [_vm._v("確定")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.goToBack()
              },
            },
          },
          [_vm._v("戻る")]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.addDialogVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.addDialogVisible = $event
              },
            },
          },
          [
            _c("span", [_vm._v("スタッフを追加してもよろしいですか？")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.addDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.onAdd } }, [_vm._v("はい")]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.choiceVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.choiceVisible = $event
              },
            },
          },
          [
            _c("span", [
              _vm._v("スタッフが設定されていませんが、よろしいですか？"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.choiceVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.goConfirm } }, [
                  _vm._v("はい"),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }