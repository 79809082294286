var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-position": "top" } },
    [
      _c(
        "el-row",
        [
          _vm._l(_vm.photos, function (photo, index) {
            return _c(
              "el-col",
              { key: index, staticStyle: { width: "240px" } },
              [
                _c(
                  "div",
                  {
                    class: [
                      "container-upload-img align-center justify-center d-flex",
                    ],
                  },
                  [
                    photo.src || photo.url
                      ? _c("div", { staticClass: "delete-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/close.png"),
                              width: "23px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteImage(index)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "img-upload1",
                            attrs: {
                              src: photo.src
                                ? photo.src
                                : photo.url
                                ? photo.url
                                : "",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.photos.length == 0
            ? [
                _c("div", { staticStyle: { width: "220px" } }, [
                  _c("img", {
                    staticStyle: { width: "100%", "object-fit": "cover" },
                    attrs: { src: require("@/assets/img/no_image.png") },
                  }),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            "padding-left": "12px",
            "padding-top": "8px",
            "padding-bottom": "32px",
          },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticClass: "image-col", attrs: { span: 3 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.triggerFileInput },
                },
                [_vm._v("ファイルを選択")]
              ),
              _vm._v(" "),
              _c("input", {
                ref: "fileInput",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: "image/*", multiple: "" },
                on: { change: _vm.handleFileUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-form-item", { attrs: { label: "求人番号" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.job.id || "求人作成後に自動発番されます。") +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "勤務期間" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "value-key": "",
                            placeholder: "勤務期間を選択",
                          },
                          model: {
                            value: _vm.term,
                            callback: function ($$v) {
                              _vm.term = $$v
                            },
                            expression: "term",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "勤務期間を選択", value: "" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.jobTerms(), function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.text, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isShortTerm()
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "開始日" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "picker",
                            attrs: { placeholder: "選択" },
                            model: {
                              value: _vm.work_date_start,
                              callback: function ($$v) {
                                _vm.work_date_start = $$v
                              },
                              expression: "work_date_start",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShortTerm()
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "終了日" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "picker",
                            attrs: { placeholder: "選択" },
                            model: {
                              value: _vm.work_date_end,
                              callback: function ($$v) {
                                _vm.work_date_end = $$v
                              },
                              expression: "work_date_end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "募集会社",
                        rules: _vm.rules.company_name,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "入力" },
                        model: {
                          value: _vm.company_name,
                          callback: function ($$v) {
                            _vm.company_name = $$v
                          },
                          expression: "company_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-end",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "応募締め切り" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "picker",
                                attrs: { placeholder: "選択" },
                                model: {
                                  value: _vm.application_period,
                                  callback: function ($$v) {
                                    _vm.application_period = $$v
                                  },
                                  expression: "application_period",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "募集ステータス" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "picker",
                                  attrs: {
                                    placeholder: "募集ステータスを選択",
                                  },
                                  model: {
                                    value: _vm.recruit_status,
                                    callback: function ($$v) {
                                      _vm.recruit_status = $$v
                                    },
                                    expression: "recruit_status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "募集ステータスを選択",
                                      value: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.recruitStatus(), function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: { label: item.text, value: item },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "textColor",
                      staticStyle: { "margin-top": "8px", "line-height": "1" },
                    },
                    [
                      _vm._v(
                        "※応募締め切り日を超過、または募集ステータスが『終了』になったとき、求人がアプリに非表示になります。"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "募集内容" } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 5,
                          type: "textarea",
                          placeholder: "入力",
                        },
                        model: {
                          value: _vm.recruit_description,
                          callback: function ($$v) {
                            _vm.recruit_description = $$v
                          },
                          expression: "recruit_description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: { display: "flex", "align-items": "flex-end" },
              attrs: { gutter: 20 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "給与" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "給与を選択" },
                          model: {
                            value: _vm.salary_type,
                            callback: function ($$v) {
                              _vm.salary_type = $$v
                            },
                            expression: "salary_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "給与を選択", value: "" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.salaryTypes(), function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.text, value: item },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "入力" },
                        model: {
                          value: _vm.salary,
                          callback: function ($$v) {
                            _vm.salary = $$v
                          },
                          expression: "salary",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "align-items": "flex-end" },
              attrs: { gutter: 20 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "勤務地" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "picker",
                          attrs: { placeholder: "都道府県を選択" },
                          model: {
                            value: _vm.location_prefecture_id,
                            callback: function ($$v) {
                              _vm.location_prefecture_id = $$v
                            },
                            expression: "location_prefecture_id",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "都道府県を選択", value: "" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.prefectures, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "picker",
                          attrs: { placeholder: "市区を選択" },
                          on: { focus: _vm.canSelectLocationCities },
                          model: {
                            value: _vm.location_city_id,
                            callback: function ($$v) {
                              _vm.location_city_id = $$v
                            },
                            expression: "location_city_id",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "市区を選択", value: "" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.cities, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "入力" },
                    model: {
                      value: _vm.location_other,
                      callback: function ($$v) {
                        _vm.location_other = $$v
                      },
                      expression: "location_other",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "padding-left": "12px",
          },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "勤務時間" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "開始時間" },
                    on: { change: _vm.setValidWorkTimeStart },
                    model: {
                      value: _vm.work_time_start,
                      callback: function ($$v) {
                        _vm.work_time_start = $$v
                      },
                      expression: "work_time_start",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "終了時間" },
                    on: { change: _vm.setValidWorkTimeEnd },
                    model: {
                      value: _vm.work_time_end,
                      callback: function ($$v) {
                        _vm.work_time_end = $$v
                      },
                      expression: "work_time_end",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "備考を入力" },
                    model: {
                      value: _vm.work_time_memo,
                      callback: function ($$v) {
                        _vm.work_time_memo = $$v
                      },
                      expression: "work_time_memo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "padding-left": "12px",
          },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "集合時間" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "集合時間" },
                    on: { change: _vm.setValidMeetingTime },
                    model: {
                      value: _vm.meeting_time,
                      callback: function ($$v) {
                        _vm.meeting_time = $$v
                      },
                      expression: "meeting_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "休日・休暇" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "入力" },
                    model: {
                      value: _vm.holiday,
                      callback: function ($$v) {
                        _vm.holiday = $$v
                      },
                      expression: "holiday",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "職種" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "入力" },
                    model: {
                      value: _vm.job_type,
                      callback: function ($$v) {
                        _vm.job_type = $$v
                      },
                      expression: "job_type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仕事内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: 5,
                              type: "textarea",
                              placeholder: "入力",
                            },
                            model: {
                              value: _vm.job_description,
                              callback: function ($$v) {
                                _vm.job_description = $$v
                              },
                              expression: "job_description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "応募資格" } },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: 5,
                              type: "textarea",
                              placeholder: "入力",
                            },
                            model: {
                              value: _vm.qualification,
                              callback: function ($$v) {
                                _vm.qualification = $$v
                              },
                              expression: "qualification",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "通勤・交通費" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "入力" },
                            model: {
                              value: _vm.transportation_expenses,
                              callback: function ($$v) {
                                _vm.transportation_expenses = $$v
                              },
                              expression: "transportation_expenses",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c("col", { attrs: { span: 20 } }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "福利厚生" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "入力" },
                        model: {
                          value: _vm.welfare,
                          callback: function ($$v) {
                            _vm.welfare = $$v
                          },
                          expression: "welfare",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "持ち物" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "入力" },
                            model: {
                              value: _vm.belongings,
                              callback: function ($$v) {
                                _vm.belongings = $$v
                              },
                              expression: "belongings",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c("col", { attrs: { span: 20 } }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服装" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "入力" },
                        model: {
                          value: _vm.dress,
                          callback: function ($$v) {
                            _vm.dress = $$v
                          },
                          expression: "dress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "採用人数" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "入力" },
                    model: {
                      value: _vm.number_of_hires,
                      callback: function ($$v) {
                        _vm.number_of_hires = $$v
                      },
                      expression: "number_of_hires",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "選考について" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "入力", type: "textarea", rows: 5 },
                    model: {
                      value: _vm.selection_process,
                      callback: function ($$v) {
                        _vm.selection_process = $$v
                      },
                      expression: "selection_process",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "その他" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "入力",
                              type: "textarea",
                              rows: 5,
                            },
                            model: {
                              value: _vm.other_memo,
                              callback: function ($$v) {
                                _vm.other_memo = $$v
                              },
                              expression: "other_memo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "メッセージ" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "入力",
                              type: "textarea",
                              rows: 5,
                            },
                            model: {
                              value: _vm.message,
                              callback: function ($$v) {
                                _vm.message = $$v
                              },
                              expression: "message",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "問い合わせ先" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "入力" },
                            model: {
                              value: _vm.contact,
                              callback: function ($$v) {
                                _vm.contact = $$v
                              },
                              expression: "contact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "採用担当" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "入力" },
                            model: {
                              value: _vm.recruiter,
                              callback: function ($$v) {
                                _vm.recruiter = $$v
                              },
                              expression: "recruiter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "padding-left": "12px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "アプリで表示" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "選択" },
                      model: {
                        value: _vm.is_display_app,
                        callback: function ($$v) {
                          _vm.is_display_app = $$v
                        },
                        expression: "is_display_app",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "表示", value: true },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "非表示", value: false },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }