"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _validate = require("@/utils/validate");
var _apolloClient = require("@/apollo/apolloClient");
var _clientQuery = require("@/apollo/queries/client-query");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    disableForm: {
      type: Object,
      default: function _default() {
        return {
          company_name: false,
          ceo_name: false,
          zip_code: false,
          address1: false,
          address2: false,
          phone_number: false,
          fax_number: false,
          mobile_phone: false,
          mail_address: false,
          occupation: false,
          business_content: false,
          login_id: false,
          login_password: false,
          confirmed_password: false,
          pass_check: false
        };
      }
    },
    showForm: {
      type: Object,
      default: function _default() {
        return {
          company_name: true,
          ceo_name: true,
          zip_code: true,
          address1: true,
          address2: true,
          phone_number: true,
          fax_number: true,
          mobile_phone: true,
          mail_address: true,
          occupation: true,
          business_content: true,
          login_id: true,
          login_password: true,
          confirmed_password: true,
          pass_check: true
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          company_name: '',
          ceo_name: '',
          zip_code: '',
          address1: '',
          address2: '',
          phone_number: '',
          fax_number: '',
          mobile_phone: '',
          mail_address: '',
          occupation: '',
          business_content: '',
          login_id: '',
          login_password: '',
          confirmed_password: '',
          pass_check: false
        };
      }
    },
    updateForm: {
      type: Object,
      default: function _default() {
        return {
          company_name: true,
          ceo_name: true,
          zip_code: true,
          address1: true,
          address2: true,
          phone_number: true,
          fax_number: true,
          mobile_phone: true,
          mail_address: true,
          occupation: true,
          business_content: true,
          login_id: true,
          login_password: true,
          confirmed_password: true,
          pass_check: true
        };
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '新規作成';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('メールアドレスを正しく入力してください'));
      } else {
        callback();
      }
    };
    var validateID = function validateID(rule, value, callback) {
      if (value === '') {
        callback(new Error('IDを入力してください'));
      } else {
        _apolloClient.apolloClient.query({
          query: _clientQuery.GET_LOGINID,
          variables: {
            login_id: _this.form.login_id
          }
        }).then(function (result) {
          if (_this.disableForm.login_id === true) {
            callback();
            return;
          }
          if (result.data.client_user.length > 0) {
            callback(new Error('すでに登録されているIDです'));
          } else {
            if (_this.isHanEisu(value) === false) {
              callback(new Error('IDは半角英数字で入力してください'));
            } else {
              if (value.length < 8) {
                callback(new Error('IDは8文字以上で入力してください'));
                return;
              } else if (value.length > 16) {
                callback(new Error('IDは16文字以上で入力してください'));
                return;
              }
              callback();
            }
          }
        }).catch(function (error) {
          callback(new Error(error));
        });
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (_this.form.pass_check === false || _this.form.pass_check === undefined) {
        callback();
        return;
      }
      if (value === '') {
        callback(new Error('パスワードを入力してください'));
      } else if (_this.isHanEisu(value) === false) {
        callback(new Error('パスワードは半角英数字で入力してください'));
        return;
      } else if (value.length < 8) {
        callback(new Error('パスワードは8文字以上で入力してください'));
        return;
      } else {
        if (_this.showForm.confirmed_password && _this.form.confirmed_password !== '') {
          _this.$refs.clientForm.validateField('confimedPassword');
        }
        callback();
      }
    };
    var confimedPassword = function confimedPassword(rule, value, callback) {
      if (_this.form.pass_check === false || _this.form.pass_check === undefined) {
        callback();
        return;
      }
      if (value === '') {
        callback(new Error('確認用パスワードを入力してください'));
      } else if (_this.showForm.login_password && value !== _this.form.login_password) {
        callback(new Error('パスワードが確認用の値と一致しません'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false,
      passwordType: 'password',
      capsTooltip: false,
      rules: {
        company_name: [{
          required: true,
          trigger: 'blur',
          message: '会社名を入力してください'
        }],
        cto_name: [{
          required: true,
          trigger: 'blur',
          message: '担当者を入力してください'
        }],
        zip_code: [{
          required: true,
          trigger: 'blur',
          message: '郵便番号を入力してください'
        }],
        address1: [{
          required: true,
          trigger: 'blur',
          message: '住所を入力してください'
        }],
        phone_number: [{
          required: true,
          trigger: 'blur',
          message: '電話番号を入力してください'
        }],
        mail_address: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        login_id: [{
          required: true,
          trigger: 'blur',
          validator: validateID
        }],
        login_password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        confirmed_password: [{
          required: true,
          trigger: 'blur',
          validator: confimedPassword
        }]
      }
    };
  },
  computed: {},
  methods: {
    // パスワード確認
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    activePwd: function activePwd() {
      this.form.pass_check = !this.form.pass_check;
      if (this.form.pass_check === true) {
        this.disableForm.login_password = false;
        this.disableForm.confirmed_password = false;
        this.form.login_password = '';
      } else {
        this.disableForm.login_password = true;
        this.disableForm.confirmed_password = true;
      }
    },
    submit: function submit() {
      var _this3 = this;
      console.log('submit');
      this.$refs.clientForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this3.centerDialogVisible = true;
      });
    },
    isHanEisu: function isHanEisu(str) {
      str = str === null ? '' : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    clientProcess: function clientProcess() {
      var _this4 = this;
      this.centerDialogVisible = false;
      this.$refs.clientForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        return _this4.$emit('postFormSubmit', _this4.form);
      });
    }
  }
};