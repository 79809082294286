"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkflowStatus = void 0;
var WorkflowStatus = exports.WorkflowStatus = {
  StaffRequestWorkFlow: {
    Applying: {
      val: 1,
      label: '申請中'
    },
    Checking: {
      val: 2,
      label: '確認中'
    },
    Replyed: {
      val: 3,
      label: '送付済み'
    },
    MonthlyPaymentClosing: {
      val: 4,
      label: '月払い〆後'
    },
    WeeklyPaymentClosing: {
      val: 5,
      label: '週払い〆後'
    },
    AlreadySupported: {
      val: 6,
      label: '対応済み'
    },
    Withdrawn: {
      val: 7,
      label: '取下げ'
    }
  }
};